import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../appState/rootReducer';

type Keys = 'Meta' | 'Alt' | 'Shift' | 'Control';

interface UseKeyPressedReturnValue {
  isKeyPressed: (key: Keys) => boolean;
}

// This is specifically for keys that are held down and used with another interaction
const useKeyPressed = (): UseKeyPressedReturnValue => {
  const { keysPressed } = useSelector((state: RootState) => {
    const { keysPressed } = state.ui;
    return { keysPressed };
  });

  const isKeyPressed = useCallback(
    (key: Keys) => {
      return keysPressed.indexOf(key) > -1;
    },
    [keysPressed]
  );

  return { isKeyPressed };
};

export default useKeyPressed;
