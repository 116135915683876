import { useMemo, useCallback } from 'react';
import { CodeObjectProps } from '@looop/common-types';

const useErrors = ({
  fileName,
  editingFileId,
  files
}: {
  fileName: string;
  editingFileId: string;
  files: CodeObjectProps;
}): { liveError: string | false; validateFileName: () => string | false } => {
  const liveError: string | false = useMemo(() => {
    if (fileName.includes(' ')) {
      return `File names must not contain whitespace.`;
    }

    const specialRegex = /^[a-zA-Z0-9)(+=._-]+$/g;

    if (fileName && !specialRegex.test(fileName)) {
      return `File names must not contain any special characters.`;
    }

    return false;
  }, [fileName]);

  const validateFileName = useCallback(() => {
    const fileType = files[editingFileId].type;
    const path = files[editingFileId].path || '/';
    const duplicateFile = Object.values(files).find((file) => {
      const currentFile = `${file.path || '/'}${file.name}`;
      const editingFile = `${path}${fileName}`;

      return currentFile === editingFile && editingFileId !== file.id;
    });

    if (duplicateFile) {
      return 'Duplicate file or folder found.';
    }

    if (!fileName.length) {
      return `You must specify a name.`;
    }

    if (!fileName.includes('.') && fileType !== 'folder') {
      return `File names must contain an extension.`;
    }

    if (
      !fileName.endsWith('.js') &&
      !fileName.endsWith('.json') &&
      !fileName.endsWith('.html') &&
      !fileName.endsWith('.gltf') &&
      fileType !== 'folder'
    ) {
      return `This file type is currently unsupported.`;
    }

    const htmlFile = Object.values(files).find(({ name }) =>
      name.endsWith('.html')
    );
    const isHtmlFile = fileName.endsWith('.html');

    if (isHtmlFile && htmlFile && htmlFile.id !== editingFileId) {
      return 'We only support one html file.';
    }

    return false;
  }, [fileName, editingFileId, files]);

  return { liveError, validateFileName };
};

export default useErrors;
