import { useSelector } from 'react-redux';
import { RootState } from '../appState/rootReducer';

const useIsOwner = (): boolean => {
  const isOwner = useSelector((state: RootState) => {
    const { user, project } = state;
    return (
      user.currentUser?.uid === project.authorData?.uid &&
      !project?.isTemplateOrForking
    );
  });

  return isOwner;
};

export default useIsOwner;
