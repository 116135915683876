import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MixedCheckbox } from '@reach/checkbox';
import { RootState } from '../../appState/rootReducer';
import Likes from '../../components/Likes/Likes';
import Forks from '../../components/Forks/Forks';
import Tags from '../../components/Tags/Tags';
import postMessageToParent from '../../utils/postMessageToParent';
import useProjectMeta from '../../hooks/useProjectMeta';
import useIsOwner from '../../hooks/useIsOwner';
import trimWhitespace from '../../utils/trimWhitespace';
import ButtonToggleExpand from '../../components/ButtonToggleExpand/ButtonToggleExpand';
import ExpandingTextarea from '../../components/ExpandingTextarea/ExpandingTextarea';
import classes from './ProjectMeta.module.css';

interface IProjectMetaProps {
  isExpanded: boolean;
  toggleExpand: () => void;
}

const ProjectMeta: React.FC<IProjectMetaProps> = ({
  isExpanded,
  toggleExpand
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [editingProjectName, setEditingProjectName] = useState<string | null>(
    null
  );

  // Todo: trim extra spaces or line breaks at the end
  const [editingDescription, setEditingDescription] = useState<string | null>(
    null
  );

  const { metaData, updateMetaData } = useProjectMeta();
  const isOwner = useIsOwner();
  const { currentUser, authorData } = useSelector((state: RootState) => {
    return {
      currentUser: state.user.currentUser,
      authorData: state.project.authorData
    };
  });

  const {
    title = '',
    description = '',
    updatedAt,
    createdAt,
    sourceInfo,
    status
  } = metaData;

  const isPublished = status === 'visible';

  const formattedCreatedDate = useMemo(
    () => (createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : ''),
    [createdAt]
  );
  const formattedUpdatedDate = useMemo(
    () => (updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy') : ''),
    [updatedAt]
  );

  const updateTextareaHeight = useCallback(() => {
    const value = editingDescription || description;

    // @ts-ignore
    if (textareaRef?.current?.parentNode?.dataset) {
      // @ts-ignore
      textareaRef.current.parentNode.dataset.replicatedValue = value;
    }
  }, [editingDescription, description]);

  const setAsTemplate = useCallback(() => {
    postMessageToParent({ setAsTemplate: true });
  }, []);

  const navTo = useCallback((path: string) => {
    postMessageToParent({ navTo: path });
  }, []);

  useEffect(() => {
    if (editingProjectName === null) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [editingProjectName]);

  useEffect(() => {
    updateTextareaHeight();

    if (editingDescription === null) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [editingDescription, updateTextareaHeight]);

  const projectTitle = editingProjectName !== null ? editingProjectName : title;

  return (
    <div className={classes.ProjectMeta}>
      <header className={classes.header}>
        <div className={classes.titleBackground}>
          <ButtonToggleExpand onClick={toggleExpand} isExpanded={isExpanded} />
          <input
            className={classes.title}
            data-cy="project-title"
            type="text"
            placeholder="Project title..."
            value={projectTitle}
            onChange={(e) => setEditingProjectName(e.target.value)}
            onBlur={() => {
              if (editingProjectName !== null) {
                updateMetaData({
                  title: editingProjectName
                });
              }
            }}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                updateMetaData({
                  title: editingProjectName || ''
                });
                setEditingProjectName(null);
              } else if (event.key === 'Escape') {
                setEditingProjectName(null);
              }
            }}
            disabled={!isOwner}
          />
        </div>
        <div className={classes.headerActions}>
          <Likes />
          <Forks />
        </div>
      </header>
      <div
        className={classes.contentContainer}
        style={{
          height: isExpanded ? contentRef?.current?.clientHeight || '100%' : 0
        }}
      >
        <div className={classes.content} ref={contentRef}>
          <div className={classes.author}>
            {authorData?.avatarUrl ? (
              <span className={classes.avatar}>
                <img
                  src={authorData.avatarUrl}
                  alt={`Avatar for ${authorData.username}`}
                />
              </span>
            ) : null}
            <a
              className={classes.username}
              href={`/users/${authorData.username}`}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                navTo(`/users/${authorData.username}`);
              }}
            >
              {authorData.username}
            </a>
          </div>
          {!isOwner && !description ? null : (
            <div className={classes.textareaContainer}>
              <ExpandingTextarea
                maxLength={500}
                placeholder="Project description..."
                value={
                  editingDescription !== null ? editingDescription : description
                }
                onChange={(e) => setEditingDescription(e.target.value)}
                onBlur={(e) => {
                  if (editingDescription !== null) {
                    const trimmed = trimWhitespace(editingDescription);

                    if (trimmed !== description) {
                      updateMetaData({
                        description: trimmed
                      });
                    }

                    setEditingDescription(null);
                  }
                }}
                onKeyUp={(event) => {
                  if (event.key === 'Escape') {
                    setEditingDescription(null);
                  }
                }}
                disabled={!isOwner}
                dataCy="project-description"
              />
            </div>
          )}
          <div className={classes.dates}>
            <div className={classes.created}>
              <span className={classes.dateHeading}>Created</span>
              <span className={classes.dateValue}>{formattedCreatedDate}</span>
            </div>
            <div className={classes.updated}>
              <span className={classes.dateHeading}>Last updated</span>
              <span className={classes.dateValue}>{formattedUpdatedDate}</span>
            </div>
          </div>
          <div className={classes.source}>
            <span className={classes.sourceHeading}>Source</span>
            {sourceInfo ? (
              <a
                href={`${process.env.REACT_APP_WEB_URL}/projects/${sourceInfo.docId}`}
                className={classes.sourceValueLink}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  navTo(`/projects/${sourceInfo?.docId}`);
                }}
              >
                {sourceInfo?.title || 'No project title'}
              </a>
            ) : (
              <span className={classes.sourceValue}>None</span>
            )}
          </div>
          <Tags isOwner={isOwner} />
          <div className={classes.extraButtons}>
            {isOwner && (
              <label className={classes.publishedToggle}>
                <span>Public</span>
                <div
                  className={
                    isPublished
                      ? classes.styledToggleActive
                      : classes.styledToggle
                  }
                />
                <MixedCheckbox
                  className={classes.hiddenRadioButton}
                  checked={isPublished}
                  onChange={() => {
                    updateMetaData({
                      status: status === 'visible' ? 'hidden' : 'visible'
                    });
                  }}
                />
              </label>
            )}
            {isOwner && (
              <button
                className={classes.deleteButton}
                onClick={() => {
                  postMessageToParent({ deleteProject: true });
                }}
                data-cy="delete-project-button"
              >
                Delete!
              </button>
            )}
            {currentUser?.role === 'admin' && (
              <button
                className={classes.templateButton}
                onClick={setAsTemplate}
              >
                Set as template
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectMeta;
