import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../appState/rootReducer';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import postMessageToParent from '../../utils/postMessageToParent';

import classes from './Forks.module.css';

const Forks: React.FC = () => {
  const { forkCount } = useSelector((state: RootState) => {
    const {
      projectData: { forkCount }
    } = state.project;
    return { forkCount };
  });

  return (
    <div className={classes.forks}>
      <ButtonIcon
        iconName="fork"
        className={classes.forkButton}
        onClick={() => postMessageToParent({ fork: true })}
      />
      <span className={classes.forksCount}>{forkCount}</span>
    </div>
  );
};

export default Forks;
