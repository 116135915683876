import React from 'react';
import classes from './InputNumber.module.css';

interface IInputNumberProps {
  innerRef: (el: HTMLInputElement) => HTMLInputElement;
  value: number | string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const InputNumber: React.FC<IInputNumberProps> = ({
  innerRef,
  value,
  disabled,
  onChange,
  onFocus,
  onBlur
}) => {
  return (
    <input
      ref={innerRef}
      className={classes.input}
      type="text"
      value={value}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default InputNumber;
