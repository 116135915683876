import React from 'react';
import classes from './ScreenHeader.module.css';

interface IScreenHeader {
  heading: string;
  buttons?: JSX.Element[];
  toggle?: JSX.Element[];
}

const ScreenHeader: React.FC<IScreenHeader> = ({
  heading,
  buttons,
  toggle
}) => {
  return (
    <header className={classes.ScreenHeader}>
      <div className={classes.ScreenHeaderTitle}>
        {toggle ? <div className={classes.toggle}>{toggle}</div> : null}
        <span className={classes.titleText}>{heading}</span>
      </div>
      <div className={classes.ScreenHeaderButtons}>{buttons}</div>
    </header>
  );
};

export default ScreenHeader;
