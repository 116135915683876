import { FileProps, CodeObjectProps } from '@looop/common-types';

export const sortFilesAndFolders = (
  data: Array<FileProps>
): Array<FileProps> => {
  return data
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (a.type === 'folder' && b.type === 'file') {
        return -1;
      }

      if (b.type === 'folder' && a.type === 'file') {
        return 1;
      }

      if (a.type === 'folder' && b.type === 'folder') {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
      }
      return 0;
    });
};

export const getFileTree = (files: CodeObjectProps): Array<FileProps> => {
  let nestedChildIds: Array<string> = [];

  const getTreeForItemId = (id: string): FileProps => {
    const item = files[id];
    const { childIds } = item || {};
    if (childIds && childIds.length) {
      nestedChildIds = [...nestedChildIds, ...childIds];
      return {
        ...item,
        children: sortFilesAndFolders(childIds.map(getTreeForItemId))
      };
    }
    return item;
  };

  const fileTree = Object.values(files).reduce(
    (acc: Array<FileProps>, item: FileProps) => {
      return [...acc, getTreeForItemId(item.id)];
    },
    []
  );

  const removedLeftovers = fileTree.filter(
    ({ id }) => nestedChildIds.indexOf(id) === -1
  );

  return sortFilesAndFolders(removedLeftovers);
};
