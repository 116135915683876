const msToTime = (ms: number, showMs?: boolean): string => {
  let seconds: string | number = Math.floor((ms / 1000) % 60);
  let minutes: string | number = Math.floor((ms / (1000 * 60)) % 60);

  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  if (showMs) {
    let milliseconds: string | number = Math.floor(ms).toString().slice(-3);
    milliseconds =
      milliseconds.length === 1 ? '00' + milliseconds : milliseconds;
    milliseconds =
      milliseconds.length === 2 ? '0' + milliseconds : milliseconds;
    return `${minutes}:${seconds}:${milliseconds}`;
  }

  return `${minutes}:${seconds}`;
};

export default msToTime;
