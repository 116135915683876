import React from 'react';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { RootState } from '../../appState/rootReducer';
import postMessageToParent from '../../utils/postMessageToParent';
import classes from './Likes.module.css';

const Likes: React.FC = () => {
  const { likeCount, iLike } = useSelector((state: RootState) => {
    const {
      iLike,
      projectData: { likeCount }
    } = state.project;
    return { likeCount, iLike };
  });

  return (
    <div className={classes.likes}>
      <ButtonIcon
        iconName="heart"
        className={cn(classes.likeButton, {
          [classes.likeButtonActive]: iLike
        })}
        onClick={() => postMessageToParent({ toggleLike: true })}
      />
      <span className={classes.likesCount}>{likeCount}</span>
    </div>
  );
};

export default Likes;
