import React from 'react';
import cn from 'classnames';
import classes from './ResizeHandle.module.css';

const ResizeHandle: React.FC<{
  className: string;
  handleResize: (e: MouseEvent | TouchEvent) => void;
  direction?: 'vertical' | 'horizontal';
}> = ({ className, direction = 'vertical', handleResize }) => {
  return (
    <div
      className={cn(classes.ResizeHandle, className, {
        [classes.horizontal]: direction === 'horizontal'
      })}
      onMouseDown={(e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleResize, false);
        document.addEventListener('mouseup', () => {
          document.removeEventListener('mousemove', handleResize);
        });
      }}
      onTouchStart={(e) => {
        e.preventDefault();
        document.addEventListener('touchmove', handleResize, false);
        document.addEventListener('touchend', () => {
          document.removeEventListener('touchmove', handleResize);
        });
      }}
    />
  );
};

export default ResizeHandle;
