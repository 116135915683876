import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Editor, { useMonaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import ResizeHandle from '../../components/ResizeHandle/ResizeHandle';
import useErrors from './hooks/useErrors';
import eslint from './eslint.bundle';
import config from './eslint.config.json';
import { RootState } from '../../appState/rootReducer';
import { setScreenDimension } from '../../appState/ui/uiReducer';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import postMessageToParent from '../../utils/postMessageToParent';
import {
  updateFileCode,
  setCurrentFileId,
  setUserLintErrors,
  setFileViewing,
  unSetFileViewing
} from '../../appState/project/projectReducer';
import CodeComment from './components/CodeComment/CodeComment';
import { playPause } from '../../utils/looopTimeline';
import { ISavedFile } from '@looop/common-types';
import * as SCREEN_KEYS from '../screenKeys';

import classes from './CodeEditor.module.css';

const getExtensionFromFileName = (name: string): string =>
  name.split('.')[name.split('.').length - 1];

interface ExtMap {
  [key: string]: string;
}

const getLanguageFromFileName = (name: string): string => {
  const extMap: ExtMap = {
    js: 'javascript',
    json: 'json',
    html: 'html',
    default: 'javascript'
  };

  return (name && extMap[getExtensionFromFileName(name)]) || extMap.default;
};

const editorOptions = {
  selectOnLineNumbers: true,
  minimap: {
    enabled: false
  },
  lineNumbersMinChars: 1,
  glyphMargin: true
};

export type EditorType = monaco.editor.IStandaloneCodeEditor;

let oldDecorations: string[] = [];

const CodeEditor: React.FC<{
  className: string;
  onMount: (key: string, value: number) => void;
}> = ({ className, onMount }) => {
  const monaco = useMonaco();
  const editorRef = useRef<EditorType | null>(null);
  const { projectData, editingHighlights, theme } = useSelector(
    (state: RootState) => {
      return { ...state.project, ...state.user };
    }
  );
  const dispatch = useDispatch();
  const {
    currentFileId = '',
    files = {},
    previewingId,
    viewing: viewingFiles
  } = projectData;
  const currentFile: {
    name?: string;
    path?: string;
    code?: string;
    id?: string;
  } = currentFileId ? files[currentFileId] || {} : {};
  const { name: currentFileName = '', path: currentFilePath = '/' } =
    currentFile;
  const currentFilePathName = `${currentFilePath}${currentFileName}`;
  const language = currentFileName && getLanguageFromFileName(currentFileName);

  const errors = useErrors(currentFilePathName, language);

  useLayoutEffect(() => {
    onMount(SCREEN_KEYS.CODE_EDITOR_MOBILE_X, 0);
  }, [onMount]);

  useEffect(() => {
    const model = editorRef?.current?.getModel();

    if (monaco && model) {
      monaco.editor.setModelMarkers(model, 'test', errors);
    }
  }, [errors, monaco]);

  useEffect(() => {
    if (monaco && editorRef?.current) {
      const newDecorations = editingHighlights.map(({ fileId, start, end }) => {
        if (fileId !== currentFileId) {
          return { range: new monaco.Range(0, 0, 0, 0), options: {} };
        }

        return {
          range: new monaco.Range(
            start.line,
            start.column + 1, // it's 1 off for some reason
            end.line,
            end.column + 20 // force to end of the line so we don't have to keep updating this
          ),
          options: { inlineClassName: 'editingLine' }
        };
      });

      oldDecorations = editorRef?.current.deltaDecorations(
        oldDecorations,
        newDecorations
      );

      if (editingHighlights.length) {
        editorRef?.current.revealLineInCenter(
          editingHighlights[editingHighlights.length - 1].start.line
        );
      }
    }
    // currentFile?.code is important so this updates after the code updates
  }, [monaco, editingHighlights, currentFileId, currentFile?.code]);

  useEffect(() => {
    if (currentFile?.code) {
      // @ts-ignore
      dispatch(setUserLintErrors(eslint.verify(currentFile.code, config)));
    }
  }, [dispatch, currentFile?.code]);

  const handleResizeMobile = useCallback(
    // @ts-ignore
    (e) => {
      const { pageX } = e;
      const { innerWidth } = window;
      const min = 0;
      const max = innerWidth - 24;
      let value = pageX < min ? min : pageX;
      value = value >= max ? max : value;

      dispatch(
        setScreenDimension({
          key: SCREEN_KEYS.CODE_EDITOR_MOBILE_X,
          value
        })
      );
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveFileToStorage = useCallback(
    debounce((data: ISavedFile) => {
      postMessageToParent({
        saveFileToStorage: data
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!files[currentFileId]) return;

    const { name, path, code = '' } = files[currentFileId];

    if (name.endsWith('.gltf')) {
      debouncedSaveFileToStorage({
        path: `${path}${name}`,
        value: code,
        folder: 'assets'
      });
    }
  }, [files, currentFileId, debouncedSaveFileToStorage]);

  const onChangeHandler = useCallback(
    (value: string) => {
      playPause(false);
      dispatch(updateFileCode({ value, id: currentFileId, undoable: true }));

      // Change from "previewing" to "viewing"
      if (previewingId === currentFileId) {
        dispatch(setFileViewing({ fileId: currentFileId, undoable: true }));
      }
    },
    [dispatch, currentFileId, previewingId]
  );

  const fileTabs = useMemo(() => {
    const viewing =
      viewingFiles?.map((id) => ({
        id,
        isPreviewing: false
      })) || [];

    const previewing = previewingId
      ? [
          {
            id: previewingId,
            isPreviewing: true
          }
        ]
      : [];

    const combined = [...viewing, ...previewing];

    // fallback if nothing selected
    if (!combined.length && currentFileId) {
      return [
        {
          id: currentFileId,
          isPreviewing: true
        }
      ];
    }

    return combined;
  }, [previewingId, viewingFiles, currentFileId]);

  const renderHeader = () => {
    return (
      <header className={classes.header}>
        <ul className={classes.fileTabs}>
          {fileTabs.map(({ id, isPreviewing }) => {
            if (!files[id]) return null;

            return (
              <li key={id}>
                <Button
                  className={cn(classes.fileTab, {
                    [classes.fileTabSelected]: currentFileId === id,
                    [classes.fileTabPreviewing]: isPreviewing
                  })}
                  onClick={(event) => {
                    if (event.detail === 1) {
                      dispatch(
                        setCurrentFileId({ fileId: id, undoable: true })
                      );
                    } else if (event.detail === 2) {
                      dispatch(setFileViewing({ fileId: id, undoable: true }));
                    }
                  }}
                >
                  <span
                    className={classes.closeTabButton}
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        unSetFileViewing({ fileId: id, undoable: true })
                      );
                    }}
                  >
                    <Icon iconName="x" />
                  </span>
                  <span className={classes.fileTabText}>{files[id].name}</span>
                </Button>
              </li>
            );
          })}
        </ul>
      </header>
    );
  };

  return (
    <div className={cn(className, classes.CodeEditor)}>
      <div
        className={classes.ResizeHandleMobileContainer}
        style={{ height: window.innerHeight }}
      >
        <ResizeHandle
          className={classes.ResizeHandleMobile}
          handleResize={handleResizeMobile}
        />
      </div>
      {renderHeader()}
      <div className={classes.monacoContainer}>
        <Editor
          value={currentFile.code}
          path={currentFile?.id} // Set this to something that wont change in order to persist the model that gets created
          theme={theme === 'light' ? 'vs-light' : 'vs-dark'}
          defaultValue={currentFile?.code}
          defaultLanguage={language}
          options={editorOptions}
          // @ts-ignore
          onChange={onChangeHandler}
          onMount={(editor, monaco) => {
            editorRef.current = editor;
          }}
        />
      </div>
      <CodeComment />
    </div>
  );
};

export default CodeEditor;
