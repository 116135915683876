import * as Babel from '@babel/standalone';
import { NodePath } from '@babel/core';
import * as BabelTypes from '@babel/types';
import roundToTwo from '../../../utils/roundToTwo';
import { EditingHighlights } from '@looop/common-types';

let cachedResult: string;

const getUpdatedKeyframes = ({
  code,
  keyframesToMove,
  property,
  value,
  fileId,
  range,
  round
}: {
  fileId: string;
  code: string;
  keyframesToMove: { [key: string]: number[] };
  property: 'value' | 'position';
  value: number | string | null;
  range?: [number, number];
  round?: boolean;
}): { codeString: string; editingHighlights: EditingHighlights[] } => {
  let result;
  const editingHighlights: EditingHighlights[] = [];

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getUpdatedKeyframesPlugin = (babel: typeof BabelTypes): any => {
      return {
        visitor: {
          ExpressionStatement(path: NodePath<BabelTypes.ExpressionStatement>) {
            // @ts-ignore
            const { elements } = path.node.expression;

            Object.keys(keyframesToMove).forEach((trackIndex) => {
              const keyframesToUpdate = keyframesToMove[trackIndex];
              const track = elements[trackIndex];

              keyframesToUpdate?.forEach((keyframeIndex) => {
                const keyframes = track.properties.find(
                  // @ts-ignore
                  ({ key }) => key.value === 'segments'
                );

                const keyframe = keyframes.value.elements[keyframeIndex];
                const p = keyframe.properties.find(
                  // @ts-ignore
                  ({ key }) => key.value === property
                );

                if (value !== null) {
                  let newValue =
                    typeof value === 'string'
                      ? p.value.value + parseFloat(value)
                      : value;

                  if (range) {
                    const [min, max] = range;
                    newValue = newValue < min ? min : newValue;
                    newValue = newValue > max ? max : newValue;
                  }

                  p.value.value = round ? roundToTwo(newValue) : newValue;
                }

                const { loc } = p.value;
                editingHighlights.push({
                  fileId,
                  start: { ...loc.start }, // need to clone these otherwise weird sh!t ends up in the store
                  end: { ...loc.end }
                });
              });
            });
          }
        }
      };
    };

    Babel.registerPlugin(
      'getUpdatedKeyframesPlugin',
      getUpdatedKeyframesPlugin
    );

    result = Babel.transform(code, {
      babelrc: false,
      compact: false,
      presets: [],
      sourceMaps: false,
      retainLines: true,
      plugins: ['getUpdatedKeyframesPlugin']
    });

    cachedResult = result.code || '';
  } catch (error) {
    console.error({ error });
  }

  return {
    codeString: (result?.code || cachedResult).slice(0, -1), // slice removes the pesky trailing semi-colon that babel adds!
    editingHighlights
  };
};

export default getUpdatedKeyframes;
