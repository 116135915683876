import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import { TimelineConfig } from '@looop/common-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../appState/rootReducer';
import getUpdatedKeyframes from '../utils/getUpdatedKeyframes';
import roundToTwo from '../../../utils/roundToTwo';
import useKeyPressed from '../../../hooks/useKeyPressed';
import useEventListener from '@use-it/event-listener';
import {
  setCurrentFileId,
  updateFileCode,
  setEditingHighlights
} from '../../../appState/project/projectReducer';
import InputNumber from '../../../components/InputNumber/InputNumber';
import classes from './ValueInputs.module.css';

interface TracksParams {
  id: string;
  value: number;
}

type KeyframesToMove = {
  [key: number]: number[];
};
interface ValueInputsProps {
  timelineData: TimelineConfig[];
  tracksValues?: {
    [id: string]: TracksParams;
  };
  selectedKeyframes: {
    [rowIndexIndex: string]: number; // keyframeIndex
  };
  focusedInput: number;
  setFocusedInput: (i: number) => void;
  clickedKeyframeRow: number;
  setClickedKeyframeRow: (index: number) => void;
  keyframesToMove: KeyframesToMove;
  mouseDownKeyframe: boolean;
}

const ValueInputs: React.FC<ValueInputsProps> = ({
  timelineData,
  tracksValues,
  selectedKeyframes,
  focusedInput,
  setFocusedInput,
  clickedKeyframeRow,
  keyframesToMove,
  mouseDownKeyframe
}) => {
  const inputRefs = useRef<HTMLInputElement[] | null[]>([]);
  const dispatch = useDispatch();
  const { isKeyPressed } = useKeyPressed();
  const [editingValue, setEditingValue] = useState('');

  const { files, currentFileId } = useSelector((state: RootState) => {
    const files = state.project.projectData.files || {};
    const currentFileId = state.project.projectData.currentFileId;
    return { files, currentFileId };
  });

  const configJson = useMemo(() => {
    return Object.values(files).find(
      ({ name }) => name === 'timeline.config.json'
    );
  }, [files]);

  const handleOnInputChange = useCallback(
    (value: string, rowIndex: number) => {
      const keyframeIndex = selectedKeyframes[rowIndex];
      if (configJson?.id && typeof configJson?.code === 'string') {
        setEditingValue(value);

        const regex = /^(0|[1-9]\d*)(\.\d+)?$/;

        if (!regex.test(value)) return;

        const { codeString } = getUpdatedKeyframes({
          fileId: configJson.id,
          code: configJson.code,
          keyframesToMove: {
            [rowIndex]: [keyframeIndex]
          },
          property: 'value',
          value: parseFloat(value) || 0 // the JSON file will break if there's no value, so this zero is important!
        });

        dispatch(
          updateFileCode({
            value: JSON.stringify(JSON.parse(codeString), null, 4),
            id: configJson.id,
            undoable: true
          })
        );
      }
    },
    [selectedKeyframes, configJson, dispatch]
  );

  const updateInputValue = useCallback(
    (isNegative: boolean) => {
      if (focusedInput > -1) {
        let value = 1;

        value = isKeyPressed('Alt') ? 0.1 : 1;
        value = isKeyPressed('Shift') ? 10 : value;

        const n = isNegative ? -1 * value : value;
        const v = parseFloat(editingValue);

        handleOnInputChange(roundToTwo(v + n).toString(), focusedInput);
      }
    },
    [editingValue, focusedInput, handleOnInputChange, isKeyPressed]
  );

  useEventListener('keyup', (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'ArrowUp' || key === 'ArrowDown') {
      updateInputValue(key === 'ArrowDown');
    }
  });

  useEventListener('wheel', (event: WheelEvent) => {
    const isNegative = event.deltaY < 0;
    updateInputValue(isNegative);
  });

  const handleOnFocus = useCallback(
    (value: number, rowIndex: number) => {
      if (configJson?.id && typeof configJson?.code === 'string') {
        setEditingValue(value.toString());
        setFocusedInput(rowIndex);

        if (configJson && currentFileId !== configJson.id) {
          dispatch(setCurrentFileId({ fileId: configJson.id, undoable: true }));
        }

        const keyframeIndex = selectedKeyframes[rowIndex];
        const { editingHighlights } = getUpdatedKeyframes({
          fileId: configJson.id,
          code: configJson.code,
          keyframesToMove: {
            [rowIndex]: [keyframeIndex]
          },
          property: 'value',
          value: null
        });

        dispatch(setEditingHighlights(editingHighlights));
      }
    },
    [configJson, currentFileId, selectedKeyframes, setFocusedInput, dispatch]
  );

  const focusInput = useCallback((index: number) => {
    let timeout: ReturnType<typeof setTimeout>;

    if (index > -1) {
      timeout = setTimeout(() => {
        inputRefs?.current?.[index]?.focus();
      }, 200);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    if (!mouseDownKeyframe) {
      focusInput(clickedKeyframeRow);
    }
  }, [clickedKeyframeRow, focusInput, mouseDownKeyframe]);

  useEffect(() => {
    focusInput(focusedInput);
  }, [focusInput, focusedInput, selectedKeyframes]);

  return (
    <ol className={classes.inputsList}>
      {timelineData.map(({ id, segments }, i) => {
        const isSelected = Boolean(selectedKeyframes[i] > -1);

        // Use the fixed value from the config when a keyframe is selected
        const keyframeIndex = selectedKeyframes[i];
        const { value: configValue } = segments?.[keyframeIndex] || {};

        const { value: tweenedValue = null } = tracksValues?.[id] || {};

        let value = focusedInput === i ? editingValue : configValue;

        if (!value && focusedInput === -1) {
          value =
            typeof tweenedValue === 'number' ? roundToTwo(tweenedValue) : '';
        }

        return (
          <li key={i + id} className={classes.inputRow}>
            <label className={classes.inputHeading}>{id}</label>
            <InputNumber
              innerRef={(el) => (inputRefs.current[i] = el)}
              value={value ?? ''}
              disabled={
                !isSelected || Object.values(keyframesToMove).flat().length > 1
              }
              onChange={(e) => handleOnInputChange(e.target.value, i)}
              onFocus={(e) => handleOnFocus(configValue, i)}
              onBlur={() => {
                setFocusedInput(-1);
                dispatch(setEditingHighlights([]));
              }}
            />
          </li>
        );
      })}
    </ol>
  );
};

export default ValueInputs;
