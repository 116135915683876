import React, { useMemo, useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { ThemeProps } from '@looop/common-types';
import DurationPoints from '../DurationPoints/DurationPoints';
import { seekTo } from '../../../utils/looopTimeline';
import useKeyPressed from '../../../hooks/useKeyPressed';
import classes from './ScrubBar.module.css';

type SelectedKeyframes = {
  [rowIndexIndex: string]: number;
};
interface ScrubBarProps {
  totalTime: number;
  currentTime: number;
  durationInView: number;
  timelineWidth: number;
  setDurationInView: (n: number) => void;
  isPlaying: boolean;
  setIsPlaying: (isIt: boolean) => void;
  selectedKeyframes: SelectedKeyframes;
  timelineRef: HTMLDivElement | null;
  theme?: ThemeProps;
  lastResize: number; // timestamp to trigger a re-render
  zoomWidth: number;
}

const map = (
  n: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number
) => ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;

const ScrubBar: React.FC<ScrubBarProps> = ({
  totalTime,
  currentTime,
  durationInView,
  timelineWidth,
  setDurationInView,
  selectedKeyframes,
  isPlaying,
  setIsPlaying,
  timelineRef,
  theme,
  lastResize,
  zoomWidth
}) => {
  const { isKeyPressed } = useKeyPressed();

  const playheadRef = useRef<HTMLSpanElement>(null);
  const [playheadMouseDown, setPlayheadMouseDown] = useState(false);

  const minZoomWidth = 8;
  const maxZoomWidth = timelineWidth / 2;
  const minDuration = 0.5;
  const maxDuration = totalTime > 30 ? 30 : totalTime;

  useEffect(() => {
    // Reset to ensure the playhead doesn't end up off the screen
    if (currentTime > totalTime && !isPlaying) {
      seekTo(0);
    }
  }, [totalTime, currentTime, isPlaying]);

  useEffect(() => {
    if (!zoomWidth || !maxDuration) return;

    const n = map(
      zoomWidth,
      minZoomWidth,
      maxZoomWidth,
      minDuration,
      maxDuration
    );

    setDurationInView(n);
  }, [zoomWidth, setDurationInView, maxZoomWidth, maxDuration]);

  useEffect(() => {
    const handleDragging = (e: MouseEvent) => {
      let t = currentTime + e.movementX / 100;

      // Todo: make it loop
      // t = t < 0 ? totalTime : t;
      // t = t > totalTime ? 0 : t;

      t = t < 0 ? 0 : t;
      t = t > totalTime ? totalTime : t;

      seekTo(t);
    };

    if (playheadMouseDown) {
      document.addEventListener('mousemove', handleDragging, false);
    } else {
      document.removeEventListener('mousemove', handleDragging);
    }

    return () => {
      document.removeEventListener('mousemove', handleDragging);
    };
  }, [playheadMouseDown, timelineWidth, totalTime, currentTime]);

  useEffect(() => {
    const onMouseUp = () => {
      document.exitPointerLock();
      setPlayheadMouseDown(false);
    };
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!timelineRef) return;

    const handleHorizontalScroll = (e: WheelEvent) => {
      if (Object.keys(selectedKeyframes).length) {
        return;
      }

      // Scroll
      if (isKeyPressed('Shift')) {
        // Todo: +- number rather than delta
        let t = currentTime + e.deltaX / 100;
        t = t < 0 ? 0 : t;
        t = t > totalTime ? totalTime : t;

        seekTo(t);
      }

      // Todo:
      // Zoom
      // if (isKeyPressed('Meta') || isKeyPressed('Control')) {
      // const z = zoomWidth + e.deltaY / 5;
      // handleZoom(z);
      // }
    };

    const tl = timelineRef;

    tl?.addEventListener('wheel', handleHorizontalScroll, { passive: true });

    return () => {
      tl?.removeEventListener('wheel', handleHorizontalScroll);
    };
  }, [
    isKeyPressed,
    currentTime,
    totalTime,
    timelineRef,
    zoomWidth,
    // handleZoom,
    lastResize,
    selectedKeyframes
  ]);

  const Playhead = useMemo(() => {
    const timeFraction = currentTime / totalTime;
    const x = timeFraction * timelineWidth;

    return (
      <div
        className={cn(classes.playhead, {
          [classes.playheadHidden]: isKeyPressed('Shift')
        })}
        style={{
          transform: `translateX(${x}px)`
        }}
      >
        <span
          className={classes.playheadHandle}
          ref={playheadRef}
          onMouseDown={({ button }) => {
            if (button === 0) {
              setPlayheadMouseDown(true);
              playheadRef?.current?.requestPointerLock();

              if (isPlaying) {
                setIsPlaying(false);
              }
            }
          }}
        ></span>
      </div>
    );
  }, [
    isKeyPressed,
    timelineWidth,
    currentTime,
    totalTime,
    isPlaying,
    setIsPlaying
  ]);

  return (
    <div className={classes.ScrubBar} style={{ width: timelineWidth }}>
      {Playhead}
      <DurationPoints
        timelineWidth={timelineWidth}
        totalTime={totalTime}
        durationInView={durationInView}
        currentTime={currentTime}
        theme={theme}
        lastResize={lastResize}
      />
    </div>
  );
};

export default ScrubBar;
