import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo
} from 'react';
import { createPortal } from 'react-dom';
import useEventListener from '@use-it/event-listener';
import { TimelineConfig, ThemeProps } from '@looop/common-types';
import { useDispatch, useSelector } from 'react-redux';
import useOuterClick from '../../../hooks/useOuterClick';
import { RootState } from '../../../appState/rootReducer';
import useDraw, { TracksAndKeyframes } from './hooks/useDraw';
import {
  updateFileCode,
  setCurrentFileId,
  setEditingHighlights
} from '../../../appState/project/projectReducer';
import getUpdatedKeyframes from '../utils/getUpdatedKeyframes';
import { addKeyframe, removeKeyframes } from '../utils/addRemoveKeyframes';
import { addTrack } from '../utils/addRemoveTrack';
import roundToTwo from '../../../utils/roundToTwo';
import useKeyPressed from '../../../hooks/useKeyPressed';
import ContextMenu, {
  ContextMenuItem
} from '../../../components/ContextMenu/ContextMenu';
import classes from './Tracks.module.css';

type SelectedKeyframes = {
  [rowIndexIndex: string]: number;
};

type KeyframesToMove = {
  [key: number]: number[];
};
interface TracksProps {
  timelineData: TimelineConfig[];
  totalTime: number;
  currentTime: number;
  clearSelection: () => void;
  durationInView: number;
  timelineWidth: number;
  selectedKeyframes: SelectedKeyframes;
  setTimelineWidth: (w: number) => void;
  setSelectedKeyframes: (d: SelectedKeyframes) => void;
  keyframesToMove: KeyframesToMove;
  setKeyframesToMove: (d: KeyframesToMove) => void;
  setFocusedInput: (i: number) => void;
  focusedInput: number;
  setClickedKeyframeRow: (index: number) => void;
  theme?: ThemeProps;
  lastResize: number; // timestamp to trigger a re-render
  setMouseDownKeyframe: (val: boolean) => void;
  mouseDownKeyframe: boolean;
}

const TRACK_HEIGHT = 39;
const KEYFRAME_RADIUS = 6.5;

const Tracks: React.FC<TracksProps> = ({
  timelineData,
  durationInView,
  setTimelineWidth,
  timelineWidth,
  currentTime,
  clearSelection,
  totalTime,
  selectedKeyframes,
  setSelectedKeyframes,
  keyframesToMove,
  setKeyframesToMove,
  focusedInput,
  setFocusedInput,
  setClickedKeyframeRow,
  theme,
  lastResize,
  mouseDownKeyframe,
  setMouseDownKeyframe
}) => {
  const modalRef = document.getElementById('modal-container');
  const { isKeyPressed } = useKeyPressed();
  const dispatch = useDispatch();
  const { files, currentFileId } = useSelector((state: RootState) => {
    const files = state.project.projectData.files || {};
    const currentFileId = state.project.projectData?.currentFileId;
    return { files, currentFileId };
  });

  const configJson = useMemo(() => {
    return Object.values(files).find(
      ({ name }) => name === 'timeline.config.json'
    );
  }, [files]);

  const [hoveredRow, setHoveredRow] = useState(-1);
  const [hoveredKeyframe, setHoveredKeyframe] = useState(-1);
  const [editingConfig, setEditingConfig] = useState<string | null>(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0
  });
  const [contextMenuItems, setContextMenuItems] = useState<
    ContextMenuItem[] | null
  >(null);
  const innerContextMenuRef = useOuterClick(() => setContextMenuItems(null));

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const keyframesRef = useRef<TracksAndKeyframes>({
    tracks: [],
    keyframes: []
  });

  const canvas = canvasRef?.current;

  const metaKeyPressed = useMemo(() => isKeyPressed('Meta'), [isKeyPressed]);
  const shiftKeyPressed = useMemo(() => isKeyPressed('Shift'), [isKeyPressed]);

  const draw = useDraw({
    canvas,
    timelineData: editingConfig ? JSON.parse(editingConfig) : timelineData,
    setTimelineWidth,
    trackHeight: TRACK_HEIGHT,
    keyframeRadius: KEYFRAME_RADIUS,
    keyframesRef,
    totalTime,
    durationInView,
    currentTime,
    setSelectedKeyframes,
    selectedKeyframes,
    hoveredRow,
    hoveredKeyframe,
    keyframesToMove,
    mouseDownKeyframe,
    theme
  });

  useEventListener('mousedown', (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target !== canvas && target?.tagName !== 'INPUT') {
      clearSelection();
    }
  });

  useEffect(() => {
    draw();
  }, [draw, lastResize]);

  const handleMouseMove = useCallback(
    // @ts-ignore
    (e) => {
      const { keyframes } = keyframesRef.current;
      const canvas = canvasRef.current;

      if (!canvas) return;

      const newRowIndex = Math.floor(e.offsetY / TRACK_HEIGHT);

      let newKeyframeIndex = -1;

      if (newRowIndex > -1) {
        keyframes[newRowIndex]?.forEach(({ x, y }, j) => {
          if (
            e.offsetX <= x + KEYFRAME_RADIUS &&
            e.offsetX >= x - KEYFRAME_RADIUS
          ) {
            if (
              e.offsetY <= y + KEYFRAME_RADIUS &&
              e.offsetY >= y - KEYFRAME_RADIUS
            ) {
              newKeyframeIndex = j;
            }
          }
        });
      }

      if (newRowIndex <= timelineData.length - 1) {
        setHoveredRow(newRowIndex);
      } else {
        setHoveredRow(-1);
      }

      if (newKeyframeIndex > -1 && newKeyframeIndex !== hoveredKeyframe) {
        setHoveredKeyframe(newKeyframeIndex);
      } else if (newKeyframeIndex === -1 && hoveredKeyframe !== -1) {
        setHoveredKeyframe(-1);
      }
    },
    [hoveredKeyframe, timelineData]
  );

  const handleUpdateMoveToList = useCallback(
    (isFirst?: boolean) => {
      // Add / remove from moveTo list
      const totalKeyframes = timelineData[hoveredRow].segments.length;
      const keyframeIndex = hoveredKeyframe % totalKeyframes;

      if (keyframeIndex === -1) return;

      let clonedKeyframes = { ...keyframesToMove };

      let isAdding = true;

      if (isFirst) {
        // Clear everything else if not shift clicking
        clonedKeyframes = {
          [hoveredRow]: [keyframeIndex]
        };
      } else if (clonedKeyframes[hoveredRow]) {
        const currentIndex = clonedKeyframes[hoveredRow].indexOf(keyframeIndex);
        if (currentIndex > -1) {
          isAdding = false;
          clonedKeyframes[hoveredRow].splice(currentIndex, 1);

          if (!clonedKeyframes[hoveredRow].length) {
            // delete as we check this in the draw func to restore the fill state
            delete clonedKeyframes[hoveredRow];
          }
        } else {
          clonedKeyframes[hoveredRow].push(keyframeIndex);
        }
      } else {
        clonedKeyframes[hoveredRow] = [keyframeIndex];
      }

      setKeyframesToMove(clonedKeyframes);

      // Select config file
      if (isAdding && configJson && currentFileId !== configJson.id) {
        dispatch(setCurrentFileId({ fileId: configJson.id, undoable: true }));
      }
    },
    [
      hoveredRow,
      hoveredKeyframe,
      keyframesToMove,
      timelineData,
      setKeyframesToMove,
      configJson,
      dispatch,
      currentFileId
    ]
  );

  const handleDeleteKeyframe = useCallback(
    (keyframes?: KeyframesToMove) => {
      if (!configJson?.code) return;

      if (keyframes) {
        const updatedConfig = removeKeyframes({
          code: configJson?.code || '',
          keyframes
        });

        dispatch(
          updateFileCode({
            value: updatedConfig,
            id: configJson.id,
            undoable: true
          })
        );
      } else {
        const { segments } = timelineData[hoveredRow];
        const i = hoveredKeyframe % segments.length;

        const updatedConfig = removeKeyframes({
          code: configJson?.code,
          keyframes: {
            [hoveredRow]: [i]
          }
        });

        dispatch(
          updateFileCode({
            value: updatedConfig,
            id: configJson.id,
            undoable: true
          })
        );
      }

      setHoveredKeyframe(-1);
      setKeyframesToMove({});
    },
    [
      dispatch,
      hoveredKeyframe,
      timelineData,
      hoveredRow,
      configJson,
      setKeyframesToMove
    ]
  );

  const handleAddKeyframe = useCallback(
    (x: number) => {
      if (!configJson?.code) return;

      const { duration, delay = 0, segments } = timelineData[hoveredRow];

      // Todo: handle when adding keyframe: >= 0 && < delay

      const pixelsPerSecond = timelineWidth / durationInView;
      const delayOffset = delay * duration * pixelsPerSecond;

      const widthRepeats = totalTime / durationInView;
      const pixelsOffScreen =
        (currentTime / totalTime) * (timelineWidth * (widthRepeats - 1));

      const clickedTime = (x + pixelsOffScreen - delayOffset) / pixelsPerSecond;

      const currentLoop = Math.floor(clickedTime / duration);
      const timeOffset = currentLoop * duration;

      const position = roundToTwo((clickedTime - timeOffset) / duration);

      const updatedConfig = addKeyframe({
        code: configJson?.code,
        position,
        trackIndex: hoveredRow
      });

      dispatch(
        updateFileCode({
          value: updatedConfig,
          id: configJson.id,
          undoable: true
        })
      );

      // We need to find the new index to set the focus, so need to simulate adding
      // the new keyframe into the segments array
      const segmentsCopy = [...segments];
      segmentsCopy.push({ position, value: 0 });
      segmentsCopy.sort((a, b) => a.position - b.position);
      const keyframeIndex = segmentsCopy.findIndex(
        (keyframe) => keyframe.position === position
      );
      setKeyframesToMove({ [hoveredRow]: [keyframeIndex] });
      setSelectedKeyframes({ [hoveredRow]: keyframeIndex });
      setFocusedInput(hoveredRow);
    },
    [
      configJson?.code,
      configJson?.id,
      timelineData,
      hoveredRow,
      timelineWidth,
      durationInView,
      totalTime,
      currentTime,
      dispatch,
      setKeyframesToMove,
      setFocusedInput,
      setSelectedKeyframes
    ]
  );

  const handleAddRemoveKeyframe = useCallback(
    (e: MouseEvent) => {
      if (!configJson?.code) return;

      const { offsetX, offsetY } = e;
      const trackIndex = Math.floor(offsetY / TRACK_HEIGHT);

      if (!timelineData[trackIndex]) return; // no track here...

      if (hoveredKeyframe > -1) {
        handleDeleteKeyframe();
      } else {
        handleAddKeyframe(offsetX);
      }
    },
    [
      configJson,
      timelineData,
      hoveredKeyframe,
      handleDeleteKeyframe,
      handleAddKeyframe
    ]
  );

  const handleAddTrack = useCallback(() => {
    if (!configJson?.code) return;

    const updatedConfig = addTrack({
      code: configJson?.code
    });

    dispatch(
      updateFileCode({
        value: updatedConfig,
        id: configJson.id,
        undoable: true
      })
    );
  }, [dispatch, configJson]);

  const handleDeleteTrack = useCallback(() => {
    if (!configJson?.code) return;

    const updatedConfig = addTrack({
      code: configJson?.code,
      index: hoveredRow
    });

    dispatch(
      updateFileCode({
        value: updatedConfig,
        id: configJson.id,
        undoable: true
      })
    );
  }, [dispatch, hoveredRow, configJson]);

  const handleSelectSingleKeyframe = useCallback(() => {
    setClickedKeyframeRow(hoveredRow);

    const { segments } = timelineData[hoveredRow];
    const actualIndex = hoveredKeyframe % segments.length;

    const isAlreadySelected =
      keyframesToMove[hoveredRow]?.indexOf(actualIndex) > -1;

    if (isAlreadySelected) return;

    setSelectedKeyframes({ [hoveredRow]: actualIndex });
    handleUpdateMoveToList(true);
  }, [
    setClickedKeyframeRow,
    keyframesToMove,
    hoveredRow,
    timelineData,
    setSelectedKeyframes,
    hoveredKeyframe,
    handleUpdateMoveToList
  ]);

  const handleMouseDown = useCallback(
    // @ts-ignore
    (e) => {
      setClickedKeyframeRow(-1); // reset it to ensure the focus remains, otherwise the iframe steels it!

      const { button } = e;
      const altKeyPressed = isKeyPressed('Alt'); // todo: pass into draw and only set hover if !

      if (button === 0) {
        setMouseDownKeyframe(true);
      }

      if (
        button === 0 &&
        !shiftKeyPressed &&
        !altKeyPressed &&
        hoveredKeyframe !== -1
      ) {
        handleSelectSingleKeyframe();
      } else if (button === 0 && shiftKeyPressed) {
        handleUpdateMoveToList();
      } else if (button === 0 && isKeyPressed('Alt')) {
        handleAddRemoveKeyframe(e);
      } else if (hoveredKeyframe === -1) {
        setKeyframesToMove({});
        setSelectedKeyframes({});
      }
    },
    [
      setClickedKeyframeRow,
      isKeyPressed,
      shiftKeyPressed,
      hoveredKeyframe,
      setMouseDownKeyframe,
      handleSelectSingleKeyframe,
      handleUpdateMoveToList,
      handleAddRemoveKeyframe,
      setKeyframesToMove,
      setSelectedKeyframes
    ]
  );
  const handleRightClick = useCallback(
    // @ts-ignore
    (e) => {
      e.preventDefault();

      const { pageX, pageY, offsetX } = e;

      setContextMenuPosition({ x: pageX, y: pageY });

      if (hoveredKeyframe > -1 && hoveredRow > -1) {
        setContextMenuItems([
          {
            text: 'Delete keyframe',
            shortcut: '⌥ + Click',
            onClick: () => {
              handleDeleteKeyframe();
              setContextMenuItems(null);
            }
          }
        ]);
      } else if (hoveredRow > -1) {
        setContextMenuItems([
          {
            text: 'Add keyframe',
            shortcut: '⌥ + Click',
            onClick: () => {
              handleAddKeyframe(offsetX);
              setContextMenuItems(null);
            }
          },
          // add track above / below?
          // move track up / down ?
          {
            text: 'Delete track',
            // shortcut: '⌥ + Click',
            onClick: () => {
              handleDeleteTrack();
              setContextMenuItems(null);
            }
          }
        ]);
      } else {
        setContextMenuItems([
          {
            text: 'Add track',
            // shortcut: '⌥ + Click',
            onClick: () => {
              handleAddTrack();
              setContextMenuItems(null);
            }
          }
        ]);
      }
    },
    [
      handleAddTrack,
      handleDeleteTrack,
      hoveredRow,
      hoveredKeyframe,
      handleDeleteKeyframe,
      handleAddKeyframe
    ]
  );

  useEffect(() => {
    if (typeof configJson?.code !== 'string' || focusedInput > -1) return;

    const { editingHighlights } = getUpdatedKeyframes({
      fileId: configJson.id,
      code: configJson?.code,
      keyframesToMove,
      property: 'position',
      value: null
    });

    dispatch(setEditingHighlights(editingHighlights));
  }, [keyframesToMove, configJson, focusedInput, dispatch]);

  const maxEditingDuration = useMemo(() => {
    let maxDuration = 0;

    Object.keys(keyframesToMove).forEach((trackIndex) => {
      const { duration } = timelineData[parseInt(trackIndex)];
      if (duration > maxDuration) {
        maxDuration = duration;
      }
    });

    return maxDuration;
  }, [timelineData, keyframesToMove]);

  useEffect(() => {
    const handleMoveKeyframe = (e: MouseEvent) => {
      if (typeof configJson?.code === 'string') {
        const increments = `${
          (e.movementX * 0.0005 * durationInView) / maxEditingDuration
        }`;

        const { codeString } = getUpdatedKeyframes({
          fileId: configJson.id,
          code: editingConfig ? editingConfig : configJson?.code,
          keyframesToMove,
          property: 'position',
          value: increments, // making it a string will +=...
          range: [0, 1]
        });

        setEditingConfig(codeString);
      }
    };

    if (mouseDownKeyframe && Object.keys(keyframesToMove).length) {
      canvasRef?.current?.requestPointerLock();
      document.addEventListener('mousemove', handleMoveKeyframe, false);
    } else {
      document.removeEventListener('mousemove', handleMoveKeyframe);
    }

    return () => {
      document.removeEventListener('mousemove', handleMoveKeyframe);
    };
  }, [
    maxEditingDuration,
    editingConfig,
    mouseDownKeyframe,
    hoveredRow,
    hoveredKeyframe,
    metaKeyPressed,
    keyframesToMove,
    configJson,
    durationInView
  ]);

  useEffect(() => {
    const onMouseUp = () => {
      if (editingConfig && configJson?.id) {
        const { codeString } = getUpdatedKeyframes({
          fileId: configJson.id,
          code: editingConfig,
          keyframesToMove,
          property: 'position',
          value: '0', // making it a string will +=...
          round: true
        });

        dispatch(
          updateFileCode({
            value: JSON.stringify(JSON.parse(codeString), null, 4),
            id: configJson.id,
            undoable: true
          })
        );
      }
      setEditingConfig(null);
      setMouseDownKeyframe(false);
      document.exitPointerLock();
    };
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    dispatch,
    editingConfig,
    configJson,
    keyframesToMove,
    setMouseDownKeyframe
  ]);

  useEffect(() => {
    if (hoveredKeyframe > -1) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }

    draw();
  }, [draw, hoveredKeyframe]);

  useEventListener('resize', draw);

  useEventListener('keyup', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setContextMenuItems(null);
    }
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas?.addEventListener('mousemove', handleMouseMove);

    return () => {
      canvas?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas?.addEventListener('mousedown', handleMouseDown);

    return () => {
      canvas?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas?.addEventListener('contextmenu', handleRightClick);

    return () => {
      canvas?.removeEventListener('contextmenu', handleRightClick);
    };
  }, [handleRightClick]);

  useEffect(() => {
    const onKeypress = ({ key }: KeyboardEvent) => {
      if (!Object.keys(selectedKeyframes).length) {
        return;
      }

      if (key === 'Backspace' || key === 'Delete') {
        handleDeleteKeyframe(keyframesToMove);
      }
    };
    document?.addEventListener('keydown', onKeypress);

    return () => {
      document?.removeEventListener('keydown', onKeypress);
    };
  }, [handleDeleteKeyframe, keyframesToMove, selectedKeyframes]);

  return (
    <>
      <canvas ref={canvasRef} className={classes.canvas} />
      {contextMenuItems &&
        modalRef &&
        createPortal(
          <ContextMenu
            items={contextMenuItems}
            x={contextMenuPosition.x}
            y={contextMenuPosition.y}
            innerRef={innerContextMenuRef}
          />,
          modalRef
        )}
    </>
  );
};

export default Tracks;
