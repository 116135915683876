import { combineReducers } from '@reduxjs/toolkit';

import projectReducer from './project/projectReducer';
import userReducer from './user/userReducer';
import uiReducer from './ui/uiReducer';

const rootReducer = combineReducers({
  project: projectReducer,
  user: userReducer,
  ui: uiReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
