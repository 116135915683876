import { PostMessageProps } from '@looop/common-types';
const { REACT_APP_WEB_URL } = process.env;

const postMessageToParent = (data: PostMessageProps): void => {
  if (REACT_APP_WEB_URL) {
    window.parent.postMessage(data, REACT_APP_WEB_URL);
  } else {
    throw Error('process.env.REACT_APP_WEB_URL has not been defined');
  }
};

export default postMessageToParent;
