import cn from 'classnames';
import React from 'react';
import Button from '../Button/Button';
import classes from './ButtonToggleExpand.module.css';

interface IButtonToggleExpandProps {
  onClick: () => void;
  isExpanded: boolean;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

const ButtonToggleExpand: React.FC<IButtonToggleExpandProps> = ({
  onClick,
  isExpanded,
  children,
  className
}) => {
  return (
    <Button
      onClick={onClick}
      className={cn(
        {
          [classes.isExpanded]: isExpanded,
          [classes.ButtonToggle]: !isExpanded
        },
        className
      )}
    >
      {children ? children : null}
      <span className="visually-hidden">
        {isExpanded ? 'Click to collapse' : 'Click to expand'}
      </span>
    </Button>
  );
};

export default ButtonToggleExpand;
