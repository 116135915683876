import React, { useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../appState/rootReducer';
import { setScreenDimension } from '../../appState/ui/uiReducer';
import { setUserCodeUpdated } from '../../appState/project/projectReducer';
import useCode from './hooks/useCode';
import ResizeHandle from '../../components/ResizeHandle/ResizeHandle';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import ScreenHeader from '../../components/ScreenHeader/ScreenHeader';
import postMessageToParent from '../../utils/postMessageToParent';
import { getCurrentTime } from '../../utils/looopTimeline';
import useIsOwner from '../../hooks/useIsOwner';
import * as SCREEN_KEYS from '../screenKeys';

import classes from './VisualPreview.module.css';

declare global {
  interface Window {
    // Todo: replace any with Timeline types (once they're done!)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onScriptLoaded: (timeline: any) => void;
  }
}

const VisualPreview: React.FC<{
  className: string;
  onMount: (key: string, value: number) => void;
}> = ({ className, onMount }) => {
  const dispatch = useDispatch();
  const htmlRef = useRef<HTMLDivElement | null>(null);
  const isOwner = useIsOwner();

  const { screens, assetPaths } = useSelector((state: RootState) => {
    const { ui, project } = state;
    const { screens } = ui;

    return {
      screens,
      assetPaths: project.assetPaths
    };
  });

  const { bundledCode, html } = useCode({ assetPaths });

  const handleInitScreenshot = useCallback(() => {
    const canvas = document.querySelector('#viz-preview canvas');

    const screenShotImage = {
      data: (canvas as HTMLCanvasElement)?.toDataURL('image/png'),
      width: canvas?.clientWidth ?? 0,
      height: canvas?.clientHeight ?? 0
    };

    postMessageToParent({
      takeScreenshot: !screenShotImage.data,
      startTime: getCurrentTime(),
      screenShotImage
    });
  }, []);

  useLayoutEffect(() => {
    const defaultDesktopWidth = (window.innerWidth - 325) / 2;
    onMount(SCREEN_KEYS.VISUAL_PREVIEW_WIDTH, defaultDesktopWidth);
    onMount(SCREEN_KEYS.VISUAL_PREVIEW_MOBILE_X, 0);
  }, [onMount]);

  const updateJS = useCallback(() => {
    let scriptTag = document.getElementById('user-code') as HTMLScriptElement;
    if (scriptTag?.parentNode) {
      scriptTag.parentNode.removeChild(scriptTag);
    }

    scriptTag = document.createElement('script');
    scriptTag.type = 'module';
    scriptTag.id = 'user-code';
    scriptTag.innerHTML = bundledCode;
    document.body.appendChild(scriptTag);
  }, [bundledCode]);

  useEffect(() => {
    if (htmlRef.current) {
      htmlRef.current.innerHTML = html;
      updateJS();
    }
  }, [html, updateJS]);

  useEffect(() => {
    /**
     * `window.onScriptLoaded(timeline);` replaces `export { timeline };`
     * in the users code, then we call it here to kick things off...
     */
    window.onScriptLoaded = () => {
      // Timeline need to be populated with some data from the looop.js engine
      // console.info('users code loaded and ran!');
      dispatch(setUserCodeUpdated());
    };
  }, [dispatch]);

  const projectExplorerWidth = screens[SCREEN_KEYS.PROJECT_EXPLORER_WIDTH];
  const handleResize = useCallback(
    // @ts-ignore
    (e) => {
      const { pageX } = e;
      const { innerWidth } = window;
      const min = 0;
      const max = innerWidth - projectExplorerWidth;
      let value = innerWidth - pageX;
      value = value <= min ? min : value;
      value = value > max ? max : value;

      dispatch(
        setScreenDimension({ key: SCREEN_KEYS.VISUAL_PREVIEW_WIDTH, value })
      );
    },
    [dispatch, projectExplorerWidth]
  );

  const handleResizeMobile = useCallback(
    // @ts-ignore
    (e) => {
      e.preventDefault();
      const { pageX } = e;
      const { innerWidth } = window;
      const min = 0;
      const max = innerWidth - 12;
      let value = pageX < min ? min : pageX;
      value = value >= max ? max : value;

      dispatch(
        setScreenDimension({
          key: SCREEN_KEYS.VISUAL_PREVIEW_MOBILE_X,
          value
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={className}>
      <ResizeHandle
        className={classes.ResizeHandle}
        handleResize={handleResize}
      />
      <div
        className={classes.ResizeHandleMobileContainer}
        style={{ height: window.innerHeight }}
      >
        <ResizeHandle
          className={classes.ResizeHandleMobile}
          handleResize={handleResizeMobile}
        />
      </div>
      <ScreenHeader
        heading=""
        buttons={[
          <ButtonIcon
            onClick={handleInitScreenshot}
            iconName="photo"
            key="screenshot-button"
            title="Click to generate preview screenshot"
            disabled={!isOwner}
          />
        ]}
      />
      <div
        id="viz-preview"
        className={classes.VisualPreviewContent}
        ref={htmlRef}
      >
        preview!
      </div>
    </div>
  );
};

export default VisualPreview;
