import React, {
  useMemo,
  useState,
  useEffect,
  MouseEvent,
  KeyboardEvent,
  useRef
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { getFileTree } from './helpers/fileHelpers';
import { RootState } from '../../appState/rootReducer';
import {
  updateFileName,
  expandCollapseFolder,
  setCurrentFileId,
  setFilePreviewing,
  setFileViewing,
  moveFile,
  deleteFile,
  addNewFile,
  removeNewFile
} from '../../appState/project/projectReducer';
import ScreenHeader from '../../components/ScreenHeader/ScreenHeader';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import ButtonToggleExpand from '../../components/ButtonToggleExpand/ButtonToggleExpand';
import Icon from '../../components/Icon/Icon';
import useErrors from './helpers/useErrors';

import classes from './FileBrowser.module.css';

interface ItemRefsProps {
  [id: string]: HTMLDivElement;
}

interface IProjectMetaProps {
  isExpanded: boolean;
  toggleExpand: () => void;
}

enum FileTypes {
  file = 'file',
  folder = 'folder'
}

const FileBrowser: React.FC<IProjectMetaProps> = ({
  isExpanded,
  toggleExpand
}) => {
  const dispatch = useDispatch();
  const { projectData } = useSelector((state: RootState) => state.project);
  const { currentFileId, files = {} } = projectData;
  const itemRefs = useRef<ItemRefsProps>({});
  const contentRef = useRef<HTMLOListElement>(null);

  const [focusedFileId, setFocusedFileId] = useState('');
  const [editingFileId, setEditingFileId] = useState('');
  const [updatingFileName, setUpdatingFileName] = useState('');
  const [draggingId, setDraggingId] = useState('');
  const [draggingOverId, setDraggingOverId] = useState('');
  const { liveError, validateFileName } = useErrors({
    fileName: updatingFileName,
    editingFileId,
    files
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // This ensure the error message goes away when the user starts typing.
    // It's not perfect because it compares to the name in the store,
    // not the current value. Ideally we want to compare prev v current
    if (updatingFileName !== files[focusedFileId]?.name) {
      setError(null);
    }
  }, [updatingFileName, files, focusedFileId]);

  const fileTree = useMemo(() => getFileTree(files), [files]);

  const handleDragDrop = () => {
    const subject = files[draggingId];
    const target =
      draggingOverId === 'root'
        ? { type: 'root', id: '', path: '/', name: '' }
        : files[draggingOverId];

    if (target.type === 'file') {
      return;
    }

    const newPathName =
      draggingOverId === 'root'
        ? `/${subject.name}`
        : `${target.path}${target.name}/${subject.name}`;

    const duplicateFile = Object.values(files).find((file) => {
      const currentFile = `${file.path}${file.name}`;
      return currentFile === newPathName;
    });

    if (duplicateFile && duplicateFile.id !== draggingId) {
      handleStartEditing(subject.id);
      setError(
        'Duplicate file found. Please rename this file other the one before moving.'
      );
    } else {
      dispatch(
        moveFile({
          undoable: true,
          subject,
          target
        })
      );

      restoreFocus(draggingId);
    }
  };

  const handleAddFile = (type: FileTypes, parentId?: string) => {
    const id = uuidv4();
    const sharedProps = {
      id,
      type,
      name: '',
      isNew: true
    };
    const templates = {
      file: {
        ...sharedProps,
        code: ''
      },
      folder: {
        ...sharedProps,
        childIds: []
      }
    };

    const newFileOrFolder = templates[type];

    if (!newFileOrFolder) return;

    dispatch(addNewFile({ newFileOrFolder, parentId, undoable: true }));
    setEditingFileId(id);
  };

  const handleClickFile = (event: MouseEvent, fileId: string) => {
    const selectedFile = files[fileId];

    // Only update project history when selecting a different file
    const isUndoable =
      focusedFileId !== fileId && selectedFile.type !== FileTypes.folder;

    if (event.screenX === 0) return; // stop keyboard enter press from triggering this

    setFocusedFileId(fileId);

    if (selectedFile.type === FileTypes.file) {
      dispatch(setCurrentFileId({ fileId, undoable: isUndoable }));

      if (event.detail === 1) {
        dispatch(setFilePreviewing({ fileId, undoable: isUndoable }));
      } else if (event.detail === 2) {
        dispatch(setFileViewing({ fileId, undoable: isUndoable }));
      }
    } else if (selectedFile.type === FileTypes.folder && !editingFileId) {
      dispatch(expandCollapseFolder({ fileId, undoable: isUndoable }));
    }
  };

  const handleStartEditing = (id?: string) => {
    setUpdatingFileName(files[id || focusedFileId].name);
    setEditingFileId(id || focusedFileId);
  };

  const resetEditingStates = () => {
    setEditingFileId('');
    setUpdatingFileName('');
    setError(null);
  };

  const handleUpdateFileName = (keepFocus?: boolean, undoable?: boolean) => {
    dispatch(
      updateFileName({
        id: editingFileId,
        value: updatingFileName,
        undoable
      })
    );
    resetEditingStates();
    restoreFocus(keepFocus ? editingFileId : '');
  };

  const handleOnKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (editingFileId) {
        const errorMessage = validateFileName();

        if (errorMessage) {
          setError(errorMessage);
        } else {
          // Finish editing
          handleUpdateFileName(true, true);
        }
      } else if (focusedFileId) {
        // Start editing
        handleStartEditing();
      }
    } else if (event.key === 'Escape' && editingFileId) {
      // Cancel editing
      resetEditingStates();
      restoreFocus(editingFileId);
      dispatch(removeNewFile());
    }
  };

  const handleBlurFileOrFolder = () => {
    // When editing, we want to ensure we don't expand/collapse when clicking off,
    // so this delay ensures these get called after handleClickFile gets called
    if (error) {
      resetEditingStates();
      return;
    }

    setTimeout(() => {
      const errorMessage = validateFileName();
      if (errorMessage) {
        setError(errorMessage);
      } else {
        handleUpdateFileName();
      }
    }, 150);
  };

  const restoreFocus = (id: string) => {
    // Delay as the element needs to be added back into the DOM
    setTimeout(() => {
      if (itemRefs.current[id]) {
        itemRefs.current[id].focus();
        setFocusedFileId(id);
        dispatch(setCurrentFileId({ fileId: id, undoable: false }));
      }
    }, 150);
  };

  const renderFileTree = (data = fileTree) => {
    return data.map((item, index) => {
      if (!item) return null;

      const { id, name, children = [], type, expanded } = item;

      const liveName = updatingFileName;
      // editingFileId === id && updatingFileName ? updatingFileName : name;

      const renderFileOrCaretIcon = () => (
        <Icon
          iconName={
            type === FileTypes.file
              ? FileTypes.file
              : expanded
              ? 'chevron-down'
              : 'chevron-right'
          }
        />
      );

      const renderFileFolderButtons = () => (
        <>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              handleAddFile(FileTypes.file, id);
            }}
          >
            <Icon iconName="file-add" />
          </button>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              handleAddFile(FileTypes.folder, id);
            }}
          >
            <Icon iconName="folder-add" />
          </button>
        </>
      );

      const renderButtons = () => (
        <>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              handleStartEditing(id);
            }}
          >
            <Icon iconName="edit" />
          </button>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              if (
                window.confirm(`Are you sure you want to delete this ${type}?`)
              ) {
                dispatch(deleteFile({ fileId: id, undoable: true }));
              }
            }}
          >
            <Icon iconName="trash" />
          </button>
        </>
      );

      const nonEditingProps = {
        ref: (el: HTMLDivElement) => (itemRefs.current[id] = el),
        onKeyUp: handleOnKeyUp,
        role: 'button',
        tabIndex: index, // Makes me focusable!
        className: cn(classes.fileOrFolderItem, {
          [classes.folderCollapsed]: type === FileTypes.folder && !expanded,
          [classes.draggingOver]:
            id === draggingOverId && type === FileTypes.folder,
          [classes.focused]: id === currentFileId
        }),
        // @ts-ignore
        type: 'button',
        onClick: (e: MouseEvent) => handleClickFile(e, id),
        draggable: true,
        onDragStart: () => {
          setDraggingId(id);
        },
        onDragEnd: () => {
          if (draggingId && draggingOverId) {
            handleDragDrop();
          }
          setDraggingId('');
          setDraggingOverId('');
        },
        onDragOver: (e: MouseEvent) => {
          e.stopPropagation();
          setDraggingOverId(id);
        },
        autoFocus: currentFileId === id
      };

      return (
        <li key={id} className={classes.itemContainer}>
          {editingFileId === id ? (
            <div
              className={cn(classes.fileOrFolderItemEditing, {
                [classes.folderCollapsed]: type === 'folder' && !expanded,
                [classes.error]: error || liveError
              })}
            >
              {renderFileOrCaretIcon()}
              <input
                type="text"
                className={classes.fileOrFolderTextInput}
                value={liveName}
                onChange={(e) => setUpdatingFileName(e.currentTarget.value)}
                onKeyUp={handleOnKeyUp}
                onBlur={handleBlurFileOrFolder}
                spellCheck="false"
                autoFocus
              />
              <span className={classes.errorMessage}>{liveError || error}</span>
            </div>
          ) : (
            <div {...nonEditingProps}>
              {renderFileOrCaretIcon()}
              <span className={classes.fileFolderName}>{name}</span>
              <span className={classes.fileHoverButtonRow}>
                {type === 'folder' ? renderFileFolderButtons() : null}
                {renderButtons()}
              </span>
            </div>
          )}
          {children.length ? (
            <ol className={classes.fileFolderList}>
              {renderFileTree(children)}
            </ol>
          ) : null}
        </li>
      );
    });
  };

  return (
    <div className={classes.fileBrowser}>
      <ScreenHeader
        heading="Files"
        toggle={[
          <ButtonToggleExpand
            key="toggle"
            onClick={toggleExpand}
            isExpanded={isExpanded}
          />
        ]}
        buttons={[
          <ButtonIcon
            onClick={() => handleAddFile(FileTypes.file)}
            iconName="file-add"
            key="file-add"
            className={classes.headerButton}
          />,
          <ButtonIcon
            onClick={() => handleAddFile(FileTypes.folder)}
            iconName="folder-add"
            key="folder-add"
            className={classes.headerButton}
          />
        ]}
      />
      {fileTree.length ? (
        <div
          className={classes.contentContainer}
          style={{
            height: isExpanded
              ? (contentRef?.current?.clientHeight ?? 0) || '100%'
              : 0
          }}
        >
          <ol
            id="root"
            ref={contentRef}
            onDragOver={() => {
              setDraggingOverId('root');
            }}
            className={`${classes.fileFolderListRoot} ${
              draggingOverId === 'root' ? classes.draggingOver : ''
            }`}
          >
            {renderFileTree()}
          </ol>
        </div>
      ) : null}
    </div>
  );
};

export default FileBrowser;
