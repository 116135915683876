const colors = {
  baseDarkA: '#202121',
  baseDarkB: '#242626',
  baseDarkC: '#282a2b',
  baseDarkD: '#2c2f30',
  baseLightA: '#fcffff',
  baseLightB: '#f5f7f7',
  baseLightC: '#ebf2f2',
  baseLightD: '#afb2b3',
  tealA: '#408080',
  tealB: '#59b2b2',
  tealBOpaque: '#59b2b215',
  tealC: '#7a8f99',
  tealD: '#515f66',
  tealE: '#13bf94',
  redB: '#f27b79',
  yellowA: '#f2e76d'
};

export default colors;
