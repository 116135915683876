import React, { useEffect, useMemo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { format } from 'date-fns';
import { Patches } from '@looop/common-types';
import postMessageToParent from '../../utils/postMessageToParent';
import { RootState } from '../../appState/rootReducer';
import {
  goToPatch,
  undo,
  redo,
  setHasShownDetachedWarning,
  setOverwriteHistory,
  setNotDetached
} from '../../appState/project/projectReducer';
import ScreenHeader from '../../components/ScreenHeader/ScreenHeader';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import ModalInfo from '../../components/ModalInfo/ModalInfo';
import ModalDetachedHistory from '../../components/ModalDetachedHistory/ModalDetachedHistory';
import Button from '../../components/Button/Button';
import ButtonToggleExpand from '../../components/ButtonToggleExpand/ButtonToggleExpand';
import classes from './ProjectHistory.module.css';
import Icon from '../../components/Icon/Icon';

interface IProjectHistoryProps {
  isExpanded: boolean;
  toggleExpand: () => void;
}

const ProjectHistory: React.FC<IProjectHistoryProps> = ({
  isExpanded,
  toggleExpand
}) => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const {
    isDetached,
    hasShownDetachedWarning,
    overwriteHistory,
    undoStack = [],
    undoStackPointer = -1,
    isAdmin
  } = useSelector((state: RootState) => {
    const { isDetached, hasShownDetachedWarning, overwriteHistory } =
      state.project;
    const { undoStack, undoStackPointer } = state.project.projectData;
    // const { userData } = state.user || {};
    return {
      isDetached,
      hasShownDetachedWarning,
      overwriteHistory,
      undoStack,
      undoStackPointer,
      isAdmin: false // userData.role === 'admin'
    };
  });
  const modalRef = document.getElementById('modal-container');
  const itemRefs = useRef<HTMLLIElement[]>([]);

  useEffect(() => {
    if (!isPlaying) return;

    if (!undoStack?.length || undoStackPointer === undoStack.length - 1) {
      setIsPlaying(false);
      return;
    }

    const timeout = setTimeout(() => {
      dispatch(goToPatch(undoStackPointer + 1));
    }, 150);

    return () => clearTimeout(timeout);
  }, [dispatch, isPlaying, undoStack, undoStackPointer]);

  useEffect(() => {
    itemRefs.current[undoStackPointer]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }, [undoStackPointer]);

  useEffect(() => {
    const length = undoStack.length || 0;
    if (undoStackPointer === length - 1) {
      dispatch(setNotDetached());
    }
  }, [dispatch, undoStackPointer, undoStack]);

  const list: {
    text: string;
    time: string;
    index: number;
    comment?: string;
  }[] = useMemo(() => {
    // Temporary - we will need a better solution for when these lists get huge!
    return undoStack
      ?.filter(({ timestamp }: Patches) => timestamp)
      ?.map(({ actionType, timestamp, comment }: Patches, i: number) => {
        return {
          text: actionType,
          time: format(new Date(timestamp), 'hh:mm:ss'),
          index: i,
          comment: comment?.value
        };
      })
      .reverse();
  }, [undoStack]);

  return (
    <div className={classes.ProjectHistory}>
      <div className={classes.header}>
        <ScreenHeader
          heading="Project history"
          toggle={[
            <ButtonToggleExpand
              key="toggle"
              onClick={toggleExpand}
              isExpanded={isExpanded}
            />
          ]}
          buttons={[
            isAdmin ? (
              <span key="count" className={classes.count}>
                {undoStackPointer + 1} of {list?.length || 0}
              </span>
            ) : (
              <span key="blank" />
            ),
            <button
              key="info"
              onClick={() => setShowModalInfo(true)}
              disabled={!isDetached && !overwriteHistory}
              className={cn(classes.info, {
                [classes.infoBrowse]: isDetached,
                [classes.infoOverwrite]: overwriteHistory
              })}
            >
              i
            </button>
          ]}
        />
      </div>
      {list.length ? (
        <>
          <div
            className={classes.contentContainer}
            style={{
              height: isExpanded ? '100%' : 0
            }}
          >
            <ol className={classes.list}>
              {list?.map(({ text, time, index, comment }) => {
                return (
                  <li
                    key={`${index}-${time}`}
                    ref={(el: HTMLLIElement) => (itemRefs.current[index] = el)}
                  >
                    <button
                      className={cn(classes.action, {
                        [classes.actionSelected]: undoStackPointer === index
                      })}
                      type="button"
                      onClick={() => dispatch(goToPatch(index))}
                    >
                      <span>{time}</span>
                      <span className={classes.actionText}>{text}</span>
                      {comment ? (
                        <div className={classes.commentIconContainer}>
                          <Icon
                            iconName="comment"
                            className={classes.commentIcon}
                          />
                        </div>
                      ) : null}
                    </button>
                  </li>
                );
              })}
            </ol>
          </div>
          <div className={classes.footer}>
            <div className={classes.buttonGroup}>
              <ButtonIcon
                className={classes.playbackButton}
                iconName="skip-back"
                onClick={() => {
                  dispatch(goToPatch(0));
                }}
                disabled={!list?.length || undoStackPointer <= 0}
              />
              <ButtonIcon
                className={classes.playbackButton}
                iconName={isPlaying ? 'pause' : 'play'}
                onClick={() => setIsPlaying(!isPlaying)}
                disabled={
                  !list?.length || undoStackPointer === list?.length - 1
                }
              />
              <ButtonIcon
                className={classes.playbackButton}
                iconName="skip-forward"
                onClick={() => {
                  if (!list?.length) return;
                  dispatch(goToPatch(list.length - 1));
                }}
                disabled={
                  !list?.length || undoStackPointer === list?.length - 1
                }
              />
            </div>
            <div className={classes.buttonGroup}>
              <ButtonIcon
                className={classes.playbackButton}
                iconName="arrow-down"
                onClick={() => dispatch(undo())}
                disabled={!list?.length || undoStackPointer <= 0}
                title="shift + ctrl + ↓"
              />
              <ButtonIcon
                className={classes.playbackButton}
                iconName="arrow-up"
                onClick={() => dispatch(redo())}
                disabled={
                  !list?.length || undoStackPointer === list?.length - 1
                }
                title="shift + ctrl + ↑"
              />
            </div>
          </div>
        </>
      ) : null}

      {showModalInfo &&
        modalRef &&
        createPortal(
          <ModalInfo
            handleClose={() => setShowModalInfo(false)}
            heading="Project history info..."
            content={
              overwriteHistory
                ? [
                    <p key="p1">
                      The next change you make will overwrite any history items
                      from this point forward!
                    </p>,
                    <p key="p2">
                      To resume as normal, simply close this modal and select
                      the latest item in the history.
                    </p>
                  ]
                : [
                    <p key="p1">
                      You&apos;re currently making changes that will be
                      overwritten the next time you navigate in the project
                      history. You will be unable to save these changes, however
                      you can always fork and create a new project from where
                      you are at.
                    </p>,
                    <p key="p2">
                      To resume as normal, simply close this modal and select
                      the latest item in the history.
                    </p>
                  ]
            }
          />,
          modalRef
        )}
      {isDetached && !hasShownDetachedWarning && modalRef
        ? createPortal(
            <ModalDetachedHistory
              handleClose={() => dispatch(setNotDetached())}
              buttons={[
                <Button
                  key="overwrite"
                  onClick={() => {
                    dispatch(setHasShownDetachedWarning(true));
                    dispatch(setOverwriteHistory(true));
                  }}
                  className={classes.modalButton}
                  danger
                >
                  Continue & Overwrite
                </Button>,
                <Button
                  key="browse"
                  onClick={() => dispatch(setHasShownDetachedWarning(true))}
                  className={classes.modalButton}
                >
                  Continue
                </Button>,
                <Button
                  key="fork"
                  onClick={() => {
                    dispatch(setHasShownDetachedWarning(true));
                    postMessageToParent({ fork: true });
                  }}
                  className={classes.modalButton}
                  inverted
                >
                  Fork
                </Button>
              ]}
            />,
            modalRef
          )
        : null}
    </div>
  );
};

export default ProjectHistory;
