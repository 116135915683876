import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonToggleExpand from '../../../../components/ButtonToggleExpand/ButtonToggleExpand';
import ExpandingTextarea from '../../../../components/ExpandingTextarea/ExpandingTextarea';
import { updateCodeComment } from '../../../../appState/project/projectReducer';
import useIsOwner from '../../../../hooks/useIsOwner';
import { RootState } from '../../../../appState/rootReducer';
import classes from './CodeComment.module.css';
import trimWhitespace from '../../../../utils/trimWhitespace';

const CodeComment: React.FC = () => {
  const dispatch = useDispatch();
  const { commentValue } = useSelector((state: RootState) => {
    const { undoStackPointer, undoStack } = state.project.projectData;

    return {
      commentValue: undoStack[undoStackPointer]?.comment?.value || ''
    };
  });
  const isOwner = useIsOwner();
  const [editingValue, setEditingValue] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const headerHeight =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--header-heights'
        )
      ) || 0;

    const totalHeight = isExpanded
      ? (contentRef?.current?.offsetHeight || 0) + headerHeight
      : headerHeight;

    document.documentElement.style.setProperty(
      '--code-comment-height',
      `${totalHeight}px`
    );
  }, [editingValue, commentValue, isExpanded]);

  useEffect(() => {
    setIsExpanded(Boolean(commentValue));
  }, [commentValue]);

  return (
    <div className={classes.CodeComment}>
      <header className={classes.header}>
        <ButtonToggleExpand
          onClick={() => setIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
          className={classes.buttonToggle}
        >
          <span className={classes.headingText}>Comment</span>
        </ButtonToggleExpand>
      </header>
      <div className={classes.content} ref={contentRef}>
        <ExpandingTextarea
          value={editingValue !== null ? editingValue : commentValue}
          onChange={(e) => setEditingValue(e.target.value)}
          onBlur={() => {
            const trimmed = trimWhitespace(editingValue || '');
            dispatch(updateCodeComment({ value: trimmed }));
            setEditingValue(null);
          }}
          placeholder={
            !isOwner && !commentValue
              ? 'No comment for this history item'
              : 'Add a comment...'
          }
          disabled={!isOwner}
        />
      </div>
    </div>
  );
};

export default CodeComment;
