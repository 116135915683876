import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../appState/rootReducer';

const useTimelineData = (): [] => {
  const timelineDataBackupRef = useRef<[]>([]);

  /**
   * We want to ensure that whenever the user changes some code that's not the config,
   * that it doesn't trigger a re-render and in turn redraw everything!
   */

  const data = useSelector((state: RootState) => {
    const files = state.project.projectData.files || {};

    const file = Object.values(files).find(
      ({ name }) => name === 'timeline.config.json'
    );

    let hasErrors = false;

    // Return null if there's an error

    if (file?.code) {
      try {
        JSON.parse(file?.code);
        hasErrors = false;
      } catch (error) {
        hasErrors = true;
      }
    }

    return hasErrors ? null : file?.code;
  });

  const timelineData = useMemo(() => {
    // Use the backup until the JSON is valid
    if (data) {
      timelineDataBackupRef.current = JSON.parse(data);
      return timelineDataBackupRef.current;
    }

    return timelineDataBackupRef.current;
  }, [data]);

  return timelineData;
};

export default useTimelineData;
