import { useCallback } from 'react';
import { TimelineConfig, ThemeProps } from '@looop/common-types';
import colors from '@looop/styles/colors';
import roundToTwo from '../../../../utils/roundToTwo';

const { baseDarkC, baseLightC, baseDarkD, baseLightD, yellowA, tealC } = colors;

type SelectedKeyframes = {
  [rowIndexIndex: string]: number;
};

export interface TracksAndKeyframes {
  tracks: [];
  keyframes: {
    x: number;
    y: number;
    t: number;
    rowIndex: number;
    keyframeIndex: number;
  }[][];
}

interface UseDrawProps {
  canvas: HTMLCanvasElement | null;
  timelineData: TimelineConfig[];
  setTimelineWidth: (n: number) => void;
  trackHeight: number;
  keyframeRadius: number;
  keyframesRef: {
    current: TracksAndKeyframes;
  };
  totalTime: number;
  durationInView: number;
  currentTime: number;
  setSelectedKeyframes: (d: SelectedKeyframes) => void;
  selectedKeyframes: SelectedKeyframes;
  hoveredRow: number;
  hoveredKeyframe: number;
  keyframesToMove: { [key: number]: number[] };
  mouseDownKeyframe: boolean;
  theme?: ThemeProps;
}

const useDraw = ({
  canvas,
  timelineData,
  setTimelineWidth,
  trackHeight,
  keyframeRadius,
  keyframesRef,
  totalTime,
  durationInView,
  currentTime,
  hoveredRow,
  hoveredKeyframe,
  keyframesToMove,
  mouseDownKeyframe,
  theme
}: UseDrawProps): (() => void) => {
  const ctx = canvas?.getContext('2d');

  const setup = useCallback(
    (width: number, height: number, dpr: number) => {
      if (!canvas || !ctx) return;

      // scale the canvas by window.devicePixelRatio
      canvas.setAttribute('width', `${width * dpr}`);
      canvas.setAttribute('height', `${height * dpr}`);

      // use css to bring it back to regular size
      canvas.setAttribute('style', `width=" ${width}; height="${height}";`);

      // set the scale of the context
      canvas.getContext?.('2d')?.scale(dpr, dpr);

      ctx.clearRect(0, 0, width, height);
    },
    [canvas, ctx]
  );

  const drawLine = useCallback(
    ({ y, width }: { y: number; width: number }) => {
      if (!ctx) return;

      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
      ctx.strokeStyle = theme === 'light' ? baseLightD : baseDarkD;
      ctx.stroke();
    },
    [ctx, theme]
  );

  const drawTrack = useCallback(
    ({ x, y, width }: { x: number; y: number; width: number }) => {
      if (!canvas || !ctx) return;
      if (!ctx || x < -width || x > canvas.clientWidth) return;
      ctx.beginPath();
      ctx.fillStyle = theme === 'light' ? baseLightC : baseDarkC;
      ctx.fillRect(x, y + 1, width, trackHeight - 2);
      ctx.fill();
    },
    [ctx, canvas, trackHeight, theme]
  );

  const drawCircle = useCallback(
    ({
      x,
      y,
      fill,
      moveStyle
    }: {
      x: number;
      y: number;
      fill: boolean;
      moveStyle: boolean;
    }) => {
      if (!canvas || !ctx) return;
      if (!ctx || x < -keyframeRadius || x > canvas.clientWidth) return;

      if (moveStyle) {
        ctx.beginPath();
        ctx.arc(x, y, keyframeRadius - 5, 0, Math.PI * 2);
        ctx.fillStyle = theme === 'light' ? tealC : yellowA; // '#f2e76d6e';
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        ctx.arc(x, y, keyframeRadius, 0, Math.PI * 2);
        ctx.strokeStyle = theme === 'light' ? tealC : yellowA;
        ctx.stroke();
        ctx.closePath();
      } else {
        ctx.beginPath();
        ctx.arc(x, y, keyframeRadius, 0, Math.PI * 2);

        if (fill) {
          ctx.fillStyle = theme === 'light' ? tealC : yellowA;
          ctx.fill();
        } else {
          ctx.strokeStyle = theme === 'light' ? tealC : yellowA;
          ctx.stroke();
        }

        ctx.closePath();
      }
    },
    [ctx, canvas, keyframeRadius, theme]
  );

  const drawPositionValue = useCallback(
    ({ position, x, y }: { position: string; x: number; y: number }) => {
      if (!canvas || !ctx) return;

      ctx.font =
        '10px Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';

      ctx.fillStyle = '#afb2b3';
      const { width } = ctx.measureText(position);

      ctx.fillText(position, x - width / 2, y - 8);
    },
    [ctx, canvas]
  );

  return useCallback(() => {
    const ctx = canvas?.getContext('2d');

    if (!canvas || !ctx) return;

    const width = canvas?.clientWidth - 3 || 0;
    const height = canvas?.clientHeight || 0;
    const dpr = window.devicePixelRatio || 1;

    setTimelineWidth(width);

    setup(width, height, dpr);

    keyframesRef.current = {
      tracks: [],
      keyframes: []
    };

    const widthRepeats = totalTime / durationInView;
    const currentTimeOffset =
      (currentTime / totalTime) * (width * (widthRepeats - 1));

    timelineData.forEach(({ duration, segments = [], delay = 0 }, rowIndex) => {
      const pixelsPerSecond = width / durationInView;
      const scale = duration / durationInView;
      const scaledSegmentWidth = width * scale;
      const delaySeconds = delay * duration;

      const offset = delaySeconds * pixelsPerSecond;

      drawLine({ y: trackHeight * (rowIndex + 1) - 0.5, width }); // border

      keyframesRef.current.keyframes[rowIndex] = [];

      const totalSegmentsToRender =
        (durationInView / duration) * Math.ceil(widthRepeats) + 8;

      for (let loopCount = -2; loopCount < totalSegmentsToRender; loopCount++) {
        const x = loopCount * scaledSegmentWidth + offset - currentTimeOffset;
        if (x > width) break;

        if (loopCount % 2) {
          // Alternate the track background colour
          drawTrack({
            x,
            y: trackHeight * rowIndex - 0.5,
            width: scaledSegmentWidth
          });
        }
      }

      const startIndex = 0; // Todo: Math.floor(currentTimeOffset / scaledSegmentWidth) ?? 0;

      for (let loopCount = 0; loopCount < totalSegmentsToRender; loopCount++) {
        let j = 0;
        const x = loopCount * scaledSegmentWidth + offset - currentTimeOffset;

        if (x > width) break;

        const endIndex = segments.length;

        for (
          let keyframeIndex = startIndex;
          keyframeIndex < endIndex;
          keyframeIndex++
        ) {
          const { position } = segments[keyframeIndex];
          const x =
            scaledSegmentWidth * position +
            offset +
            loopCount * scaledSegmentWidth -
            currentTimeOffset;

          const y = trackHeight * (rowIndex + 1) - 0.5 - trackHeight / 2;
          const t = x / pixelsPerSecond + currentTimeOffset / pixelsPerSecond;

          const isHovered =
            hoveredKeyframe % segments.length === j && hoveredRow === rowIndex;

          const isSelected =
            keyframesToMove[rowIndex]?.indexOf(keyframeIndex) > -1;

          const isReadyToMove =
            isSelected && Object.values(keyframesToMove).flat()?.length > 1;

          const isMoveStyle =
            isReadyToMove || (isSelected && mouseDownKeyframe);

          drawCircle({
            x,
            y,
            fill:
              (isSelected && !isReadyToMove && !mouseDownKeyframe) ||
              (isHovered && !mouseDownKeyframe),
            moveStyle: isMoveStyle
          });

          if (isMoveStyle) {
            drawPositionValue({
              position: `${roundToTwo(position)}`,
              x,
              y
            });
          }

          keyframesRef.current.keyframes[rowIndex].push({
            x,
            y,
            t,
            rowIndex,
            keyframeIndex
          });

          j++;
        }
      }
    });
  }, [
    canvas,
    setTimelineWidth,
    setup,
    keyframesRef,
    totalTime,
    durationInView,
    currentTime,
    timelineData,
    drawLine,
    trackHeight,
    drawTrack,
    hoveredKeyframe,
    mouseDownKeyframe,
    hoveredRow,
    drawCircle,
    keyframesToMove,
    drawPositionValue
  ]);
};

export default useDraw;
