import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { setScreenDimension } from '../../appState/ui/uiReducer';
import ProjectMeta from '../ProjectMeta/ProjectMeta';
import FileBrowser from '../FileBrowser/FileBrowser';
import ProjectHistory from '../ProjectHistory/ProjectHistory';
import ResizeHandle from '../../components/ResizeHandle/ResizeHandle';
import * as SCREEN_KEYS from '../screenKeys';
import classes from './ProjectExplorer.module.css';

type itemTypes = 'projectMeta' | 'fileBrowser' | 'projectHistory';
type IExpandedItems = {
  [key in itemTypes]: boolean;
};

const ProjectExplorer: React.FC<{
  className: string;
  onMount: (key: string, value: number) => void;
}> = ({ className, onMount }) => {
  const [expandedItems, setExpandedItems] = useState<IExpandedItems>({
    projectMeta: true,
    fileBrowser: true,
    projectHistory: true
  });

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    onMount(SCREEN_KEYS.PROJECT_EXPLORER_WIDTH, 325);
  }, [onMount]);

  const handleResize = useCallback(
    // @ts-ignore
    (e) => {
      const { pageX } = e;
      const min = 275;
      const max = 400;
      let value = pageX > max ? max : pageX;
      value = value < min ? min : value;

      dispatch(
        setScreenDimension({ key: SCREEN_KEYS.PROJECT_EXPLORER_WIDTH, value })
      );
    },
    [dispatch]
  );

  const handleToggleExpand = useCallback(
    (itemKey: itemTypes) => {
      setExpandedItems({
        ...expandedItems,
        [itemKey]: !expandedItems[itemKey]
      });
    },
    [expandedItems]
  );

  return (
    <div className={cn(classes.ProjectExplorer, className)}>
      <ProjectMeta
        isExpanded={expandedItems.projectMeta}
        toggleExpand={() => handleToggleExpand('projectMeta')}
      />
      <FileBrowser
        isExpanded={expandedItems.fileBrowser}
        toggleExpand={() => handleToggleExpand('fileBrowser')}
      />
      <ProjectHistory
        isExpanded={expandedItems.projectHistory}
        toggleExpand={() => handleToggleExpand('projectHistory')}
      />
      <ResizeHandle
        className={classes.ResizeHandle}
        handleResize={handleResize}
      />
    </div>
  );
};

export default ProjectExplorer;
