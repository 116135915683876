import { useState, useEffect } from 'react';
import { SourceMapConsumer, SourceMapConsumerConstructor } from 'source-map';
import { useDispatch, useSelector } from 'react-redux';
import {
  CodeObjectProps,
  UserCodeErrorShape,
  IAssetPaths
} from '@looop/common-types';
import postMessageToParent from '../../../utils/postMessageToParent';
import { RootState } from '../../../appState/rootReducer';
import {
  setUserCodeError,
  setImportErrors
} from '../../../appState/project/projectReducer';

import Worker from '../../../bundler/worker';

const instance = new Worker();

interface SourceMapConsumerInitialisationOptions {
  'lib/mappings.wasm': string;
}

interface SourceMapConsumerConstructorExtended
  extends SourceMapConsumerConstructor {
  initialize: (options: SourceMapConsumerInitialisationOptions) => void;
}

(SourceMapConsumer as SourceMapConsumerConstructorExtended).initialize({
  'lib/mappings.wasm': 'https://unpkg.com/source-map@0.7.3/lib/mappings.wasm'
});

interface UseCode {
  parsing: boolean;
  bundledCode: string;
  html: string;
}

declare global {
  interface Window {
    onCodeError: (error: Error) => Promise<void>;
  }
}

interface IUseCodeProps {
  assetPaths: IAssetPaths;
}

const useCode = ({ assetPaths }: IUseCodeProps): UseCode => {
  const { files, userCodeError } = useSelector(
    // authorUid
    (
      state: RootState
    ): {
      files: CodeObjectProps;
      userCodeError: UserCodeErrorShape;
      authorUid: string;
    } => {
      const { projectData, userCodeError, authorData } = state.project;
      return {
        files: projectData.files || {},
        userCodeError,
        authorUid: authorData.uid
      };
    }
  );

  const dispatch = useDispatch();
  const [bundledCode, setBundledCode] = useState('');
  const [sourceMap, setSourceMap] = useState('');
  const [html, setHtml] = useState('');

  useEffect(() => {
    (async () => {
      try {
        // setParsing(true)
        // console.time('parsingTime');

        const parsedFiles = await instance.getBundlesWorker({
          files,
          assetPaths
        });
        // console.timeEnd('parsingTime');

        setBundledCode(parsedFiles.js);
        setHtml(parsedFiles.html);
        setSourceMap(parsedFiles.map);
        dispatch(setImportErrors(parsedFiles.importErrors));
        // setParsing(false)
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [dispatch, files, assetPaths]);

  useEffect(() => {
    const { message } = userCodeError;
    const isInfiniteLoop = Boolean(message?.includes('Infinite loop'));
    postMessageToParent({ infiniteLoopDetected: isInfiniteLoop });
  }, [userCodeError]);

  window.onCodeError = async (error) => {
    // Todo: make this nicer, e.g wrap in useEffect and test a bunch of scenarios!
    const errorParts = error.stack?.split('\n');
    const message = errorParts?.[0] || null;
    const detail = errorParts?.[1].split(':');
    const line = parseInt(detail?.[detail.length - 2] || '');
    const column = parseInt(detail?.[detail.length - 1] || '');
    if (errorParts) {
      try {
        const consumer = await new SourceMapConsumer(sourceMap);
        const errorInfo = consumer.originalPositionFor({ line, column });
        const messageParts = message?.split(' ');
        if (messageParts?.[1]) {
          messageParts[1] = errorInfo.name || '';
        }
        const adjustedColumn =
          typeof errorInfo.column === 'number' ? errorInfo.column + 1 : 0;
        const isInfiniteLoop = message?.includes('Infinite loop');
        const errorData = {
          message: isInfiniteLoop ? message : messageParts?.join(' ') || '',
          filePathName: errorInfo.source,
          name: isInfiniteLoop
            ? 'Some really long text thats only here to force a long underline...................................'
            : errorInfo.name,
          line: isInfiniteLoop ? line : errorInfo.line || 0,
          column: isInfiniteLoop ? 0 : adjustedColumn
        };
        dispatch(setUserCodeError(errorData));
        consumer.destroy();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return {
    parsing: true,
    bundledCode,
    html
  };
};

export default useCode;
