import { TimelineConfig, Segments } from '@looop/common-types';

export const addKeyframe = ({
  code,
  trackIndex,
  position
}: {
  code: string;
  trackIndex: number;
  position: number;
}): string => {
  const parsedConfig = JSON.parse(code);
  const keyframes = parsedConfig[trackIndex].segments;
  keyframes.push({ position, value: 0 });

  keyframes.sort((a: Segments, b: Segments) => {
    return a.position - b.position;
  });

  return JSON.stringify(parsedConfig, null, 4);
};

type KeyframesToMove = {
  [key: number]: number[];
};

export const removeKeyframes = ({
  code,
  keyframes
}: {
  code: string;
  keyframes: KeyframesToMove;
}): string => {
  const parsedConfig = JSON.parse(code);

  const updatedConfig = parsedConfig.map(
    (data: TimelineConfig, rowIndex: number) => {
      return {
        ...data,
        segments: data.segments.filter((_, keyframeIndex) => {
          if (!keyframes[rowIndex]) {
            return true;
          }

          return keyframes[rowIndex].indexOf(keyframeIndex) === -1;
        })
      };
    }
  );

  return JSON.stringify(updatedConfig, null, 4);
};
