import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { enablePatches } from 'immer';

import rootReducer, { RootState } from './rootReducer';

enablePatches();

const store = configureStore({
  reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
