import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import Icon, { IconProps } from '../Icon/Icon';
import classes from './ButtonIcon.module.css';

interface ButtonIconProps extends IconProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  title?: string;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  onClick,
  iconName,
  className,
  disabled,
  title
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(classes.button, className)}
      disabled={disabled}
      title={title}
      type="button"
    >
      <Icon iconName={iconName} />
    </button>
  );
};

export default ButtonIcon;
