import React from 'react';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import useEventListener from '@use-it/event-listener';
import classes from './ModalInfo.module.css';

interface ModalInfoProps {
  heading: string;
  content: JSX.Element[];
  handleClose: () => void;
}

const ModalInfo: React.FC<ModalInfoProps> = ({
  heading,
  content,
  handleClose
}) => {
  useEventListener('keyup', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  });

  return (
    <>
      <dialog className={classes.modal} open>
        <header className={classes.header}>
          {heading}
          <ButtonIcon
            className={classes.closeButton}
            iconName="x"
            onClick={handleClose}
          />
        </header>
        <div className={classes.body}>{content}</div>
      </dialog>
      <div className={classes.overlay} />
    </>
  );
};

export default ModalInfo;
