import React, { useRef, useCallback, useEffect } from 'react';
import { ThemeProps } from '@looop/common-types';
import colors from '@looop/styles/colors';
import msToTime from '../../../utils/msToTime';
import { tweenTo } from '../../../utils/looopTimeline';
import classes from './DurationPoints.module.css';

const { baseDarkB, baseLightB, tealD, baseLightD } = colors;

interface DurationPointsProps {
  timelineWidth: number;
  totalTime: number;
  durationInView: number;
  currentTime: number;
  theme?: ThemeProps;
  lastResize: number; // timestamp to trigger a re-render
}

const DurationPoints: React.FC<DurationPointsProps> = ({
  timelineWidth,
  totalTime,
  durationInView,
  currentTime,
  theme,
  lastResize
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = canvasRef?.current;
  const ctx = canvas?.getContext('2d');
  const canvasHeight = 35;

  useEffect(() => {
    const canvas = canvasRef.current;

    const handleMouseDown = (e: MouseEvent) => {
      const { offsetX } = e;

      const currentTimePercent = currentTime / totalTime;
      const currentTimeX = currentTimePercent * timelineWidth;
      const inViewPercent = durationInView / totalTime;
      const pixelsPerSecond = timelineWidth / inViewPercent / totalTime;
      const diffPixels = offsetX - currentTimeX;
      const diffSeconds = diffPixels / pixelsPerSecond;
      const toTime = currentTime + diffSeconds;

      tweenTo(toTime, currentTime, durationInView);
    };
    canvas?.addEventListener('mousedown', handleMouseDown);

    return () => {
      canvas?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [timelineWidth, durationInView, totalTime, currentTime]);

  const setup = useCallback(() => {
    if (!canvas || !ctx) return;
    const dpr = window.devicePixelRatio || 1;

    // scale the canvas by window.devicePixelRatio
    canvas.setAttribute('width', `${timelineWidth * dpr}`);
    canvas.setAttribute('height', `${canvasHeight * dpr}`);

    // use css to bring it back to regular size
    canvas.setAttribute(
      'style',
      `width=" ${timelineWidth}; height="${canvasHeight}";`
    );

    // set the scale of the context
    canvas.getContext?.('2d')?.scale(dpr, dpr);

    ctx.clearRect(0, 0, timelineWidth, canvasHeight);
  }, [canvas, ctx, timelineWidth]);

  const drawBackground = useCallback(() => {
    if (!canvas || !ctx) return;
    ctx.beginPath();
    ctx.fillStyle = theme === 'light' ? baseLightB : baseDarkB;
    ctx.fillRect(0, 0, timelineWidth, canvasHeight);
    ctx.fill();
  }, [ctx, canvas, timelineWidth, theme]);

  const drawLine = useCallback(
    ({ x, height }: { x: number; height: number }) => {
      if (!ctx) return;

      ctx.beginPath();
      ctx.moveTo(x, canvasHeight);
      ctx.lineTo(x, canvasHeight - height);
      ctx.strokeStyle = theme === 'light' ? baseLightD : tealD;
      ctx.stroke();
    },
    [ctx, theme]
  );

  const drawText = useCallback(
    ({ x, text }: { x: number; text: string }) => {
      if (!ctx) return;

      ctx.font =
        '8px Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';

      ctx.fillStyle = '#afb2b3';
      const { width } = ctx.measureText(text);

      ctx.fillText(text, x - width / 2, 22);
    },
    [ctx]
  );

  const draw = useCallback(() => {
    setup();

    drawBackground();

    const showEveryNth = 0.1; // seconds
    const totalPoints = totalTime / showEveryNth;
    const widthRepeats = totalTime / durationInView;
    const totalWidth = timelineWidth * widthRepeats;
    const spacing = totalWidth / totalPoints;

    const currentTimeOffset =
      (currentTime / totalTime) * (timelineWidth * (widthRepeats - 1));

    let t = 0;
    for (let i = 0; i <= totalPoints; i++) {
      const isMultipleOfTen = i % 10 === 0;

      const h = isMultipleOfTen ? 8 : 4;
      const x = i * spacing - currentTimeOffset;

      if (x > timelineWidth) break;

      drawLine({ x, height: h });

      if (isMultipleOfTen) {
        drawText({ x, text: `${msToTime(t * 1000)}s` });
        t++;
      } else if (durationInView <= 3) {
        const ms = parseInt(`${t - 1}${i % 10}0`);
        drawText({ x, text: `${ms}ms` });
      }
    }
  }, [
    setup,
    drawBackground,
    drawLine,
    drawText,
    totalTime,
    durationInView,
    timelineWidth,
    currentTime
  ]);

  useEffect(() => {
    draw();
  }, [draw, lastResize]);

  return <canvas ref={canvasRef} className={classes.pointsCanvas} />;
};

export default DurationPoints;
