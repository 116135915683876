import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { original, applyPatches } from 'immer';
import {
  ProjectDocument,
  ProjectState,
  FileProps,
  Patches,
  UserCodeErrorShape,
  MonacoErrorShape,
  UserDataProps,
  EditingHighlights,
  SourceInfo,
  IAssetPaths
} from '@looop/common-types';
import {
  deleteChildIds,
  getNestedPath,
  produceWithPatch,
  cleanupSelectedFile
} from './projectReducer.helpers';

const userCodeErrorDefaults = {
  message: null,
  filePathName: null,
  name: null,
  line: -1,
  column: -1
};

const initialState: ProjectState = {
  iLike: false,
  codeUpdatedAt: 0,
  isDetached: false,
  userCodeSuccessfullyUpdated: 0,
  userCodeError: userCodeErrorDefaults,
  userImportErrors: [],
  userLintErrors: [],
  editingHighlights: [],
  apiError: '',
  authorData: {
    username: '',
    displayName: '',
    uid: '',
    avatarUrl: ''
  },
  assetPaths: {},
  projectData: {
    createdAt: null,
    currentFileId: '', // maybe move up?
    description: '',
    docId: '',
    files: {}, // maybe move up?
    filesUrl: '',
    images: {
      thumbnail: {
        url: '',
        width: 0,
        height: 0
      }
    },
    status: 'hidden',
    previewingId: '',
    source: '',
    tags: [],
    title: '',
    uid: '',
    updatedAt: null,
    username: '',
    viewing: [], // maybe move up?
    undoStackFilterCap: 0, // maybe move up?
    undoStackPointer: -1, // maybe move up?
    undoStack: [], // maybe move up?
    undoStackUrl: ''
  }
};

// TODO: test all this!!!
const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addNewFile: (
      state,
      action: PayloadAction<{
        newFileOrFolder: FileProps;
        parentId?: string;
        undoable: boolean;
      }>
    ) => {
      const { newFileOrFolder, parentId, undoable } = action.payload;
      return produceWithPatch({
        state,
        undoable,
        actionType: 'addNewFile',
        mutations: (draft) => {
          const newFileId = newFileOrFolder.id;
          const files = original(state.projectData?.files) || {};
          const filesClone = JSON.parse(JSON.stringify(files));

          filesClone[newFileId] = newFileOrFolder;
          draft.projectData.currentFileId = newFileId;

          if (newFileOrFolder.type === 'file') {
            if (!draft?.projectData.viewing) {
              draft.projectData.viewing = [newFileId];
            } else {
              draft.projectData.viewing.push(newFileId);
            }
          }

          if (parentId) {
            filesClone[parentId].childIds?.push(newFileOrFolder.id);
            filesClone[parentId].expanded = true;

            filesClone[newFileId].path = getNestedPath(filesClone, newFileId);
          } else {
            filesClone[newFileId].path = '/';
          }

          draft.projectData.files = filesClone;
        }
      });
    },
    setUserCodeUpdated: (state) => {
      state.userCodeSuccessfullyUpdated = Date.now();
    },
    deleteFile: (
      state,
      action: PayloadAction<{
        fileId: string;
        undoable: boolean;
      }>
    ) => {
      const { fileId, undoable } = action.payload;
      return produceWithPatch({
        state,
        undoable,
        actionType: 'deleteFileOrFolder',
        mutations: (draft) => {
          if (draft.projectData.files?.[fileId].type === 'file') {
            delete draft.projectData.files[fileId];
            deleteChildIds(draft, fileId);
            cleanupSelectedFile(draft, fileId);
          } else {
            const idsToDelete = [fileId];
            const addChildIds = (id: string) => {
              const { childIds } = draft.projectData.files?.[id] || {};
              if (!childIds || !childIds.length) return;
              childIds.forEach((childId) => {
                idsToDelete.push(childId);
                addChildIds(childId);
              });
            };
            addChildIds(fileId);
            idsToDelete.forEach((id) => {
              if (!draft.projectData.files?.[id]) return;

              delete draft.projectData.files[id];
              cleanupSelectedFile(draft, id);
            });
          }
        }
      });
    },
    expandCollapseFolder: (
      state,
      action: PayloadAction<{
        fileId: string;
        undoable: boolean;
      }>
    ) => {
      const { fileId, undoable } = action.payload;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'expandCollapseFolder',
        mutations: (draft) => {
          if (!draft.projectData.files?.[fileId]) return;

          draft.projectData.files[fileId].expanded =
            !draft.projectData.files[fileId].expanded;
        }
      });
    },
    moveFile: (
      state,
      action: PayloadAction<{
        subject: FileProps;
        undoable: boolean;
        target: { id: string; type: string };
      }>
    ) => {
      const { subject, target = '', undoable } = action.payload;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'moveFile',
        mutations: (draft) => {
          if (!target || target.id === subject.id) return;

          const files = original(draft.projectData.files) || {};

          // We need to clone this, otherwise when we pass it to `getNestedPath`, it's not up to date
          const filesClone = JSON.parse(JSON.stringify(files));

          if (target.type === 'folder') {
            if (draft.projectData.files?.[target.id]) {
              // Expand target folder...
              filesClone[target.id].expanded = true;

              // Add to target folder
              filesClone[target.id].childIds?.push(subject.id);
              draft.projectData.files = filesClone;
            }
          }

          if (target.type === 'root') {
            if (draft.projectData.files?.[subject.id]) {
              draft.projectData.files[subject.id].path = '/';
            }
          }

          if (target.type === 'root' || target.type === 'folder') {
            const parent = Object.values(files).find((f) => {
              if (f.childIds) {
                return f.childIds.indexOf(subject.id) > -1;
              }
              return false;
            });

            if (parent && draft.projectData.files?.[parent.id].childIds) {
              // Remove from existing folder...
              const childIds: string[] = filesClone[
                parent.id
              ]?.childIds?.filter((id: string) => id !== subject.id);

              filesClone[parent.id].childIds = childIds;

              draft.projectData.files = filesClone;
            }

            if (draft.projectData.files?.[subject.id]) {
              // Everything must be updated before this!
              draft.projectData.files[subject.id].path = getNestedPath(
                filesClone,
                subject.id
              );
            }
          }
        }
      });
    },
    removeNewFile: (state) => {
      const files = original(state.projectData.files) || {};
      const newFile = Object.values(files).find(({ isNew }) => isNew === true);

      if (newFile) {
        delete state.projectData.files?.[newFile.id];
        state.projectData.currentFileId = '';

        state.projectData.viewing = state.projectData.viewing?.filter(
          (id) => id !== newFile.id
        );

        // Todo: remove childId from parent
      }
    },
    setAssetPaths: (state, action: PayloadAction<IAssetPaths>) => {
      state.assetPaths = action.payload;
    },
    setCurrentFileId: (
      state,
      action: PayloadAction<{ fileId: string; undoable: boolean }>
    ) => {
      const { fileId, undoable } = action.payload;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'setCurrentFileId',
        mutations: (draft) => {
          if (draft.projectData.files?.[fileId].type === 'folder') return;
          draft.projectData.currentFileId = fileId;
        }
      });
    },
    setILikeProject: (state, action: PayloadAction<boolean>) => {
      state.iLike = action.payload;
    },
    setEditingHighlights: (
      state,
      action: PayloadAction<EditingHighlights[]>
    ) => {
      state.editingHighlights = action.payload;
    },
    setFilePreviewing: (
      state,
      action: PayloadAction<{
        fileId: string;
        undoable: boolean;
      }>
    ) => {
      const { fileId, undoable } = action.payload;

      const isAlreadyViewing = state.projectData.viewing?.find(
        (id) => id === fileId
      );

      if (isAlreadyViewing) return;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'setFilePreviewing',
        mutations: (draft) => {
          draft.projectData.previewingId = fileId;
        }
      });
    },
    setFileViewing: (
      state,
      action: PayloadAction<{ fileId: string | null; undoable: boolean }>
    ) => {
      const { fileId, undoable } = action.payload;

      if (!state?.projectData || !fileId) return;

      const isAlreadyViewing = state.projectData.viewing?.find(
        (id) => id === fileId
      );

      if (isAlreadyViewing) return;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'setFileViewing',
        mutations: (draft) => {
          if (!draft?.projectData.viewing) {
            draft.projectData.viewing = [fileId];
          } else {
            draft.projectData.viewing.push(fileId);
          }

          draft.projectData.previewingId = '';
        }
      });
    },
    setNotDetached: (state) => {
      state.overwriteHistory = false;
      state.hasShownDetachedWarning = false;
      state.isDetached = false;
    },
    setHasShownDetachedWarning: (state, action: PayloadAction<boolean>) => {
      state.hasShownDetachedWarning = action.payload;
    },
    setOverwriteHistory: (state, action: PayloadAction<boolean>) => {
      state.overwriteHistory = action.payload;
    },
    setAuthorData: (state, action: PayloadAction<UserDataProps>) => {
      state.authorData = action.payload;
    },
    setFiles: (
      state,
      action: PayloadAction<{
        [id: string]: FileProps;
      }>
    ) => {
      state.projectData.files = action.payload;
    },
    setUndoStack: (state, action: PayloadAction<Patches[]>) => {
      state.projectData.undoStack = action.payload;
      state.projectData.undoStackPointer = action.payload.length - 1;
    },
    setProjectData(state, action: PayloadAction<ProjectDocument>) {
      // Don't overwrite the files or undoStack as these are set separately as are fetched from firebase storage
      state.projectData = {
        ...action.payload,
        // These don't get saved in the firestore, so must not get overwritten...
        files: state.projectData.files,
        undoStack: state.projectData.undoStack,
        undoStackPointer: state.projectData.undoStackPointer,
        sourceInfo: state.projectData.sourceInfo
      };

      state.isTemplateOrForking = false;
    },
    setProjectSourceInfo: (state, action: PayloadAction<SourceInfo>) => {
      state.projectData.sourceInfo = action.payload;
    },
    setProjectTemplateData(
      state,
      action: PayloadAction<{
        projectData: ProjectDocument;
        authorData?: UserDataProps;
      }>
    ) {
      const { projectData, authorData } = action.payload;

      // Don't overwrite the files or undoStack as these are set separately as are fetched from firebase storage
      state.projectData = {
        ...projectData,
        // These don't get saved in the firestore, so must not get overwritten...
        files: state.projectData.files
      };

      state.isTemplateOrForking = true; // set this to true until the user saves the project

      if (!authorData) {
        state.authorData = {
          role: '',
          avatarUrl: '',
          displayName: '',
          uid: '',
          username: 'You must login in order to save!' // 🤨
        };
      } else {
        state.authorData = authorData;
      }
    },
    setUserCodeError: (state, action: PayloadAction<UserCodeErrorShape>) => {
      state.userCodeError = action.payload;
    },
    setImportErrors: (state, action: PayloadAction<MonacoErrorShape[]>) => {
      state.userImportErrors = action.payload || [];
    },
    setUserLintErrors: (state, action: PayloadAction<UserCodeErrorShape[]>) => {
      state.userLintErrors = action.payload || [];
    },
    unSetFileViewing: (
      state,
      action: PayloadAction<{ fileId: string; undoable: boolean }>
    ) => {
      if (!state?.projectData) return;

      const { fileId, undoable } = action.payload;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'unSetFileViewing',
        mutations: (draft) => {
          draft.projectData.viewing = draft.projectData.viewing?.filter(
            (id) => id !== fileId
          );

          if (draft.projectData.viewing.length) {
            const total = draft.projectData.viewing.length - 1;

            draft.projectData.currentFileId = draft.projectData.viewing[total];
          }
        }
      });
    },
    updateCodeComment: (state, action: PayloadAction<{ value: string }>) => {
      const { undoStackPointer } = state.projectData;
      const { value } = action.payload;

      state.projectData.undoStack[undoStackPointer].comment = { value };
    },
    updateFileCode: (
      state,
      action: PayloadAction<{
        value: string;
        id: string | null;
        undoable: boolean;
      }>
    ) => {
      const { value, id, undoable } = action.payload;

      if (!id) return;

      return produceWithPatch({
        state,
        undoable,
        actionType: 'codeUpdate',
        mutations: (draft) => {
          if (draft.projectData.files?.[id]) {
            draft.userCodeError = userCodeErrorDefaults;
            draft.projectData.files[id].code = value;
            draft.codeUpdatedAt = Date.now(); // used to determine if beforeunload should be called
          }
        }
      });
    },
    updateFileName: (state, action) => {
      const { id, value, undoable } = action.payload;
      return produceWithPatch({
        state,
        undoable,
        actionType: 'updateFileName',
        mutations: (draft) => {
          const { files } = draft.projectData;
          const fileOrFolder = files?.[id];

          if (!fileOrFolder) return;

          if (fileOrFolder.type === 'folder') {
            // rename any relevant file or folder paths
            const origFiles = JSON.parse(JSON.stringify(files));

            Object.keys(origFiles).forEach((key) => {
              const file = origFiles[key];
              if (
                file.path?.includes(fileOrFolder.name) &&
                draft.projectData.files?.[key]
              ) {
                origFiles[id].name = value;
                draft.projectData.files[key].path = getNestedPath(
                  origFiles,
                  key
                );
              }
            });
          }

          fileOrFolder.name = value;

          if (fileOrFolder.isNew) {
            fileOrFolder.isNew = false;
          }

          const orig = original(fileOrFolder);

          // Delete file if it's new and no name has been set...
          if (orig?.isNew && !value && draft.projectData.files?.[id]) {
            // delete new file if no name has been set
            delete draft.projectData.files[id];

            // And delete the childId reference if it's in a folder...
            deleteChildIds(draft, id);
          } else {
            // TODO: Warn user that they must create a name!?
          }
        }
      });
    },
    goToPatch: (state, action: PayloadAction<number>) => {
      const goToIndex = action.payload;

      if (state.projectData.undoStackPointer === goToIndex) return;

      // Going forwards
      if (goToIndex > state.projectData.undoStackPointer) {
        for (
          let index = state.projectData.undoStackPointer + 1;
          index <= goToIndex;
          index++
        ) {
          if (!state.projectData?.undoStack?.[index]) return;
          applyPatches(state, state.projectData.undoStack[index].patches);
        }
      } else {
        // Going backwards
        for (
          let index = state.projectData.undoStackPointer;
          index > goToIndex;
          index--
        ) {
          if (!state.projectData?.undoStack?.[index]) return;
          applyPatches(
            state,
            state.projectData.undoStack[index].inversePatches
          );
        }
      }

      state.projectData.undoStackPointer = goToIndex;
    },
    redo: (state) => {
      if (isNaN(state.projectData.undoStackPointer)) {
        return;
      }
      if (
        !state.projectData?.undoStack ||
        state.projectData.undoStackPointer ===
          state.projectData.undoStack?.length - 1
      ) {
        return;
      }

      state.projectData.undoStackPointer++;
      const i = state.projectData.undoStackPointer;

      applyPatches(state, state.projectData.undoStack[i].patches);
    },
    undo: (state) => {
      const i = state.projectData.undoStackPointer;

      if (!state.projectData.undoStack?.[i]?.inversePatches) return;

      applyPatches(state, state.projectData.undoStack[i].inversePatches);

      state.projectData.undoStackPointer--;
    }
  }
});

export const {
  addNewFile,
  deleteFile,
  expandCollapseFolder,
  setProjectData,
  setProjectTemplateData,
  setProjectSourceInfo,
  setAuthorData,
  moveFile,
  removeNewFile,
  setAssetPaths,
  setUserCodeUpdated,
  setFiles,
  setUndoStack,
  setILikeProject,
  setCurrentFileId,
  setNotDetached,
  setHasShownDetachedWarning,
  setOverwriteHistory,
  setFilePreviewing,
  setEditingHighlights,
  setFileViewing,
  unSetFileViewing,
  setUserCodeError,
  setImportErrors,
  setUserLintErrors,
  updateCodeComment,
  updateFileCode,
  updateFileName,
  goToPatch,
  redo,
  undo
} = project.actions;

export default project.reducer;
