import React, { useRef, useEffect } from 'react';
import classes from './ExpandingTextarea.module.css';

interface IExpandingTextarea {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  dataCy?: string;
}

const ExpandingTextarea: React.FC<IExpandingTextarea> = ({
  value,
  onChange,
  onBlur,
  onKeyUp,
  maxLength,
  placeholder,
  disabled,
  dataCy
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // @ts-ignore
    if (textareaRef?.current?.parentNode?.dataset) {
      // @ts-ignore
      textareaRef.current.parentNode.dataset.replicatedValue = value;
    }
  }, [value]);

  return (
    <div className={classes.growWrap}>
      <textarea
        className={classes.ExpandingTextarea}
        ref={textareaRef}
        value={value}
        onChange={onChange}
        onBlur={(e) => {
          e.target.scrollTop = 0;
          onBlur?.(e);
        }}
        onKeyUp={(e) => {
          onKeyUp?.(e);
        }}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        data-cy={dataCy}
      ></textarea>
    </div>
  );
};

export default ExpandingTextarea;
