import { TimelineConfig } from '@looop/common-types';
import roundToTwo from './roundToTwo';

export interface TracksParams {
  id: string;
  value: number;
}

type OnUpdate = ({
  currentLoopTime,
  totalLoopTime,
  tracks
}: {
  currentLoopTime: number;
  totalLoopTime: number;
  tracks: { [id: string]: TracksParams };
}) => void;

interface LoopJsTimeline {
  play: () => void;
  pause: () => void;
  seek: (seconds: number) => void;
  updateTracks: (tracks: TimelineConfig[]) => void;
  tweenToTime: ({
    time,
    duration,
    callback
  }: {
    time: number;
    duration?: number;
    callback?: () => void;
  }) => void;
  onUpdate2: OnUpdate;
  getCurrentTime: () => number;
}

declare global {
  interface Window {
    timeline: LoopJsTimeline;
  }
}
export const seekTo = (t: number): void => {
  if (window.timeline) {
    window.timeline.seek(roundToTwo(t));
  } else {
    console.warn('Looop.js timeline not found on the window object.');
  }
};

export const tweenTo = (
  t: number,
  currentTime: number,
  durationInView: number,
  callback?: () => void
): void => {
  if (window.timeline) {
    const time = roundToTwo(t);
    window.timeline.tweenToTime({
      time,
      duration: Math.abs(currentTime - time) / durationInView, // todo: use map func to set min value
      callback
    });
  } else {
    console.warn('Looop.js timeline not found on the window object.');
  }
};

export const playPause = (isPlaying: boolean): void => {
  if (window.timeline) {
    if (isPlaying) {
      window.timeline.play();
    } else {
      window.timeline.pause();
    }
  } else {
    console.warn('Looop.js timeline not found on the window object.');
  }
};

export const onUpdate = (callback: OnUpdate): void => {
  if (window.timeline) {
    window.timeline.onUpdate2 = callback;
  } else {
    console.warn('Looop.js timeline not found on the window object.');
  }
};

export const getCurrentTime = (): number => {
  if (window.timeline) {
    return window.timeline.getCurrentTime();
  }

  console.warn('Looop.js timeline not found on the window object.');
  return 0;
};
