import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEventListener from '@use-it/event-listener';
import { RootState } from '../../appState/rootReducer';
import ResizeHandle from '../../components/ResizeHandle/ResizeHandle';
import { setScreenDimension } from '../../appState/ui/uiReducer';
import Tracks from './Tracks/Tracks';
import ScrubBar from './ScrubBar/ScrubBar';
import ValueInputs from './ValueInputs/ValueInputs';
import TimelineFooter from './TimelineFooter/TimelineFooter';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import * as SCREEN_KEYS from '../screenKeys';
import useTimelineData from './useTimelineData';
import msToTime from '../../utils/msToTime';
import { playPause, onUpdate, TracksParams } from '../../utils/looopTimeline';
import classes from './Timeline.module.css';

const Timeline: React.FC<{
  className: string;
  onMount: (key: string, value: number) => void;
}> = ({ className, onMount }) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const { codeUpdate, theme } = useSelector((state: RootState) => {
    const codeUpdate = state.project.userCodeSuccessfullyUpdated;
    const { theme } = state.user;
    return { codeUpdate, theme };
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const dispatch = useDispatch();
  const timelineData = useTimelineData();
  const [zoomWidth, setZoomWidth] = useState(0);
  const [durationInView, setDurationInView] = useState(10);

  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [tracksValues, setTracksValues] = useState<{
    [id: string]: TracksParams;
  }>();

  const [focusedInput, setFocusedInput] = useState(-1);
  const [clickedKeyframeRow, setClickedKeyframeRow] = useState(-1);
  const [selectedKeyframes, setSelectedKeyframes] = useState<{
    [rowIndexIndex: string]: number; // keyframeIndex
  }>({});
  const [mouseDownKeyframe, setMouseDownKeyframe] = useState(false);

  const [keyframesToMove, setKeyframesToMove] = useState<{
    [key: number]: number[];
  }>({});

  const [lastResize, setLastResize] = useState(0);

  useEffect(() => {
    playPause(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    if (Object.keys(keyframesToMove).length) {
      setFocusedInput(-1);
    }
  }, [keyframesToMove]);

  const clearSelection = useCallback(() => {
    if (Object.keys(keyframesToMove).length) {
      setKeyframesToMove({});
    }

    if (Object.keys(selectedKeyframes).length) {
      setSelectedKeyframes({});
    }
    setFocusedInput(-1);
  }, [keyframesToMove, selectedKeyframes]);

  useEventListener('keyup', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      clearSelection();
    } else if (event.code === 'Space') {
      const { type } = document.activeElement as HTMLInputElement;

      if (type !== 'textarea' && type !== 'text' && type !== 'button') {
        setIsPlaying(!isPlaying);
      }
    }
  });

  useLayoutEffect(() => {
    if (window.timeline) {
      onUpdate(({ currentLoopTime, totalLoopTime, tracks }) => {
        setTracksValues(tracks);
        setTotalTime(totalLoopTime);
        setCurrentTime(currentLoopTime);
      });
    }
  }, [codeUpdate]);

  useLayoutEffect(() => {
    // Setting default values...
    onMount(SCREEN_KEYS.TIMELINE_HEIGHT, 150);
  }, [onMount]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setLastResize(Date.now());
    });

    if (timelineRef.current) {
      resizeObserver.observe(timelineRef.current);
    }
  }, []);

  const totalTimeFormatted = useMemo(() => {
    return msToTime(totalTime * 1000);
  }, [totalTime]);

  const currentTimeFormatted = useMemo(() => {
    return msToTime(currentTime * 1000, true);
  }, [currentTime]);

  const handleResize = useCallback(
    // @ts-ignore
    (e) => {
      const { pageY } = e;
      const { innerHeight } = window;
      const min = 50;
      const max = innerHeight - 100;
      let value = innerHeight - pageY;
      value = value <= min ? min : value;
      value = value >= max ? max : value;

      dispatch(
        setScreenDimension({
          key: SCREEN_KEYS.TIMELINE_HEIGHT,
          value
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={className}>
      <ResizeHandle
        className={classes.ResizeHandle}
        direction="horizontal"
        handleResize={handleResize}
      />
      <div
        className={classes.main}
        ref={timelineRef}
        style={{
          overflowY: Object.keys(keyframesToMove).length ? 'hidden' : 'auto'
        }}
      >
        <div className={classes.inputs}>
          <div className={classes.header}>
            <ButtonIcon
              className={classes.playPauseButton}
              iconName={isPlaying ? 'pause' : 'play'}
              onClick={() => setIsPlaying(!isPlaying)}
            />
            <div className={classes.times}>
              <span className={classes.currentTime}>
                {currentTimeFormatted}
              </span>
              <span className={classes.totalTime}>{totalTimeFormatted}</span>
            </div>
          </div>
          <ValueInputs
            timelineData={timelineData}
            tracksValues={tracksValues}
            selectedKeyframes={selectedKeyframes}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
            clickedKeyframeRow={clickedKeyframeRow}
            setClickedKeyframeRow={setClickedKeyframeRow}
            keyframesToMove={keyframesToMove}
            mouseDownKeyframe={mouseDownKeyframe}
          />
        </div>
        <div className={classes.tracks}>
          <div className={classes.header}>
            {timelineWidth ? (
              <ScrubBar
                totalTime={totalTime}
                currentTime={currentTime}
                durationInView={durationInView}
                timelineWidth={timelineWidth}
                timelineRef={timelineRef.current}
                selectedKeyframes={selectedKeyframes}
                setDurationInView={setDurationInView}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                theme={theme}
                lastResize={lastResize}
                zoomWidth={zoomWidth}
              />
            ) : null}
          </div>
          <Tracks
            timelineData={timelineData}
            totalTime={totalTime}
            currentTime={currentTime}
            clearSelection={clearSelection}
            durationInView={durationInView}
            timelineWidth={timelineWidth}
            setTimelineWidth={setTimelineWidth}
            selectedKeyframes={selectedKeyframes}
            setSelectedKeyframes={setSelectedKeyframes}
            keyframesToMove={keyframesToMove}
            setKeyframesToMove={setKeyframesToMove}
            setClickedKeyframeRow={setClickedKeyframeRow}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
            theme={theme}
            lastResize={lastResize}
            mouseDownKeyframe={mouseDownKeyframe}
            setMouseDownKeyframe={setMouseDownKeyframe}
          />
        </div>
      </div>
      <TimelineFooter
        timelineWidth={timelineWidth}
        zoomWidth={zoomWidth}
        setZoomWidth={setZoomWidth}
        lastResize={lastResize}
      />
    </div>
  );
};

export default Timeline;
