const trackDefaults = {
  id: 'new',
  duration: 5,
  delay: 0,
  wrap: false,
  // ease: "",
  segments: [
    {
      position: 0.5,
      value: 0
    }
  ]
};

export const addTrack = ({
  code,
  index
}: {
  code: string;
  index?: number;
}): string => {
  const parsedConfig = JSON.parse(code);

  if (typeof index === 'number') {
    parsedConfig.splice(index, 1);
  } else {
    parsedConfig.push(trackDefaults);
  }

  return JSON.stringify(parsedConfig, null, 4);
};
