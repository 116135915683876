import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeProps, UserDataProps } from '@looop/common-types';

interface UserState {
  currentUser?: UserDataProps | null;
  theme?: ThemeProps;
}
const initialState: UserState = {
  currentUser: null
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserDataProps | null>) => {
      state.currentUser = action.payload;
    },
    setTheme: (state, action: PayloadAction<ThemeProps>) => {
      state.theme = action.payload;
    }
  }
});

export const { setCurrentUser, setTheme } = user.actions;

export default user.reducer;
