import React, { MutableRefObject } from 'react';
import classes from './ContextMenu.module.css';

export interface ContextMenuItem {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  shortcut?: string;
}

export interface ContextMenuProps {
  items: ContextMenuItem[];
  x: number;
  y: number;
  innerRef: MutableRefObject<HTMLElement>;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ items, x, y, innerRef }) => {
  return (
    <dialog
      open
      className={classes.ContextMenu}
      ref={innerRef as MutableRefObject<HTMLDialogElement>}
      style={{
        top: y,
        left: x
      }}
    >
      {items.map(({ text, shortcut, onClick }) => {
        return (
          <button key={text} className={classes.button} onClick={onClick}>
            <span className={classes.text}>{text}</span>
            <span className={classes.shortcut}>{shortcut}</span>
          </button>
        );
      })}
    </dialog>
  );
};

export default ContextMenu;
