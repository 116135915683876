import React from 'react';
import cn from 'classnames';
import { ReactComponent as File } from './icons/file.svg';
import { ReactComponent as FileAdd } from './icons/file-add.svg';
import { ReactComponent as Folder } from './icons/folder.svg';
import { ReactComponent as FolderAdd } from './icons/folder-add.svg';
import { ReactComponent as ChevronDown } from './icons/chevron-down.svg';
import { ReactComponent as ChevronRight } from './icons/chevron-right.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Trash } from './icons/trash.svg';
import { ReactComponent as Heart } from './icons/heart.svg';
import { ReactComponent as Fork } from './icons/fork.svg';
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Pause } from './icons/pause.svg';
import { ReactComponent as SkipForward } from './icons/skip-forward.svg';
import { ReactComponent as SkipBack } from './icons/skip-back.svg';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as Photo } from './icons/photo.svg';
import { ReactComponent as Comment } from './icons/comment.svg';
import { ReactComponent as X } from './icons/x.svg';

import classes from './Icon.module.css';

export interface IconProps {
  iconName:
    | 'file'
    | 'file-add'
    | 'folder'
    | 'folder-add'
    | 'chevron-down'
    | 'chevron-right'
    | 'edit'
    | 'trash'
    | 'heart'
    | 'fork'
    | 'play'
    | 'pause'
    | 'photo'
    | 'skip-forward'
    | 'skip-back'
    | 'arrow-up'
    | 'arrow-down'
    | 'comment'
    | 'x';
  className?: string;
}

const Icon: React.FC<IconProps> = ({ iconName, className }) => {
  let icon = null;
  switch (iconName) {
    case 'file':
      icon = <File />;
      break;
    case 'file-add':
      icon = <FileAdd />;
      break;
    case 'folder':
      icon = <Folder />;
      break;
    case 'folder-add':
      icon = <FolderAdd />;
      break;
    case 'chevron-down':
      icon = <ChevronDown />;
      break;
    case 'chevron-right':
      icon = <ChevronRight />;
      break;
    case 'edit':
      icon = <Edit />;
      break;
    case 'trash':
      icon = <Trash />;
      break;
    case 'heart':
      icon = <Heart />;
      break;
    case 'fork':
      icon = <Fork />;
      break;
    case 'play':
      icon = <Play />;
      break;
    case 'pause':
      icon = <Pause />;
      break;
    case 'skip-forward':
      icon = <SkipForward />;
      break;
    case 'skip-back':
      icon = <SkipBack />;
      break;
    case 'arrow-up':
      icon = <ArrowUp />;
      break;
    case 'arrow-down':
      icon = <ArrowDown />;
      break;
    case 'comment':
      icon = <Comment />;
      break;
    case 'x':
      icon = <X />;
      break;
    case 'photo':
      icon = <Photo />;
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Incorrect icon name specified');
      return null;
  }

  return (
    <i aria-hidden className={cn(classes.iconContainer, className)}>
      {icon}
    </i>
  );
};

export default Icon;
