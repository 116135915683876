import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../appState/rootReducer';
import { setFiles, setUndoStack } from '../appState/project/projectReducer';

const useProjectFiles = (): void => {
  const dispatch = useDispatch();
  const { filesUrl, undoStackUrl } = useSelector((state: RootState) => {
    return {
      filesUrl: state.project.projectData.filesUrl,
      undoStackUrl: state.project.projectData.undoStackUrl
    };
  });

  useEffect(() => {
    if (!filesUrl) {
      dispatch(setFiles({}));
      return;
    }

    (async () => {
      try {
        const res = await fetch(filesUrl);
        const files = await res.json();
        dispatch(setFiles(files));
      } catch (error) {
        dispatch(setFiles({}));
        console.error(error);
      }
    })();
  }, [dispatch, filesUrl]);

  useEffect(() => {
    if (!undoStackUrl) {
      dispatch(setUndoStack([]));
      return;
    }

    (async () => {
      try {
        const res = await fetch(undoStackUrl);
        const undoStack = await res.json();
        dispatch(setUndoStack(undoStack));
      } catch (error) {
        dispatch(setUndoStack([]));
        console.error(error);
      }
    })();
  }, [dispatch, undoStackUrl]);
};

export default useProjectFiles;
