import { useCallback } from 'react';
import { RootState } from '../appState/rootReducer';
import { useSelector } from 'react-redux';
import postMessageToParent from '../utils/postMessageToParent';
import { IMetaData } from '@looop/common-types';

interface IUseProjectMeta {
  metaData: IMetaData;
  updateMetaData: (updatedMetaData: Partial<IMetaData>) => void;
}

const useProjectMeta = (): IUseProjectMeta => {
  const metaData = useSelector((state: RootState) => {
    const {
      title = '',
      description = '',
      updatedAt,
      createdAt,
      sourceInfo,
      status,
      tags
    } = state.project.projectData;

    return {
      title,
      description,
      updatedAt,
      createdAt,
      sourceInfo,
      status,
      tags
    };
  });

  const updateMetaData = useCallback(
    // @ts-ignore
    (updatedMetaData) => {
      postMessageToParent({
        projectMetaData: { ...metaData, ...updatedMetaData }
      });
    },
    [metaData]
  );

  return {
    metaData,
    updateMetaData
  };
};

export default useProjectMeta;
