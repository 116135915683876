import React from 'react';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import useEventListener from '@use-it/event-listener';
import classes from './ModalDetachedHistory.module.css';

interface ModalDetachedHistoryProps {
  buttons: JSX.Element[];
  handleClose: () => void;
}

const ModalDetachedHistory: React.FC<ModalDetachedHistoryProps> = ({
  buttons = [],
  handleClose
}) => {
  useEventListener('keyup', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  });

  return (
    <>
      <dialog className={classes.modal} open>
        <header className={classes.header}>
          Hang tight!
          <ButtonIcon
            className={classes.closeButton}
            iconName="x"
            onClick={handleClose}
          />
        </header>
        <div className={classes.body}>
          <p>
            You&apos;re trying to make changes and are currently not at the
            latest item in the project history.
          </p>
          <p>
            You can simply continue, but any changes will be lost as soon as you
            move in the history; or you can continue and overwrite your history;
            or you can fork and create a new project from this point.
          </p>
          <p>
            Alternatively, close this modal and go to the latest history item
            and all will be normal.
          </p>
          <div className={classes.buttons}>{buttons}</div>
        </div>
      </dialog>
      <div className={classes.overlay} />
    </>
  );
};

export default ModalDetachedHistory;
