import React, { useState } from 'react';
import cn from 'classnames';
import useProjectMeta from '../../hooks/useProjectMeta';
import Icon from '../Icon/Icon';
import classes from './Tags.module.css';

const Tags: React.FC<{ isOwner: boolean }> = ({ isOwner }) => {
  const {
    metaData: { tags },
    updateMetaData
  } = useProjectMeta();
  const [newTagName, setNewTagName] = useState('');

  return (
    <div className={cn(classes.tags, { [classes.isOwner]: isOwner })}>
      {tags.map((tag: string) => {
        return (
          <button
            key={tag}
            className={classes.tag}
            type="button"
            onClick={() => {
              if (isOwner) {
                updateMetaData({ tags: tags.filter((t) => t !== tag) });
              }
            }}
          >
            {tag}
            <Icon iconName="x" className={classes.closeIcon} />
          </button>
        );
      })}
      {isOwner && (
        <input
          type="text"
          placeholder="add tag..."
          value={newTagName}
          className={classes.addTagInput}
          onChange={(e) => setNewTagName(e.target.value)}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              if (newTagName) {
                updateMetaData({ tags: [...tags, newTagName] });
                setNewTagName('');
              }
            } else if (event.key === 'Escape') {
              setNewTagName('');
            }
          }}
        />
      )}
    </div>
  );
};

export default Tags;
