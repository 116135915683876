import React, {
  useLayoutEffect,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PostMessageEventProps } from '@looop/common-types';
import cn from 'classnames';
import useEventListener from '@use-it/event-listener';
import { RootState } from './appState/rootReducer';
import ProjectExplorer from './screens/ProjectExplorer/ProjectExplorer';
import CodeEditor from './screens/CodeEditor/CodeEditor';
import VisualPreview from './screens/VisualPreview/VisualPreview';
import Timeline from './screens/Timeline/Timeline';
import useProjectFiles from './hooks/useProjectFiles';
import {
  undo,
  redo,
  setProjectData,
  setProjectTemplateData,
  setAuthorData,
  setILikeProject,
  setProjectSourceInfo,
  setAssetPaths
} from './appState/project/projectReducer';
import {
  addPressedKey,
  removePressedKey,
  setScreenDimension
} from './appState/ui/uiReducer';
import { setCurrentUser, setTheme } from './appState/user/userReducer';
import postMessageToParent from './utils/postMessageToParent';
import { seekTo } from './utils/looopTimeline';

import '@looop/styles/reset.css';
import '@looop/styles/global.css';

import classes from './app.module.css';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [previewOnly, setPreviewOnly] = useState(false);
  const [previewAppReady, setPreviewAppReady] = useState(false);
  const [timelineStartTime, setTimelineStartTime] = useState<string | number>(
    0
  );

  useProjectFiles();

  const {
    screens,
    theme: currentTheme,
    keysPressed,
    projectData,
    userCodeUpdated,
    codeUpdatedAt
  } = useSelector((state: RootState) => {
    return {
      theme: state.user.theme,
      screens: state.ui.screens,
      keysPressed: state.ui.keysPressed,
      projectData: state.project.projectData,
      userCodeUpdated: state.project.userCodeSuccessfullyUpdated,
      codeUpdatedAt: state.project.codeUpdatedAt
    };
  });

  const handleSaveProject = useCallback(() => {
    postMessageToParent({ updatedProjectData: projectData });
  }, [projectData]);

  useEffect(() => {
    if (codeUpdatedAt) {
      postMessageToParent({ codeUpdated: true });
    }
  }, [codeUpdatedAt]);

  useEffect(() => {
    if (userCodeUpdated && previewOnly) {
      const v =
        typeof timelineStartTime === 'string'
          ? parseInt(timelineStartTime)
          : timelineStartTime;

      // Delay this slightly to ensure everything has settled
      setTimeout(() => {
        seekTo(v / 1000);
        setPreviewAppReady(true);
      }, 500);
    }
  }, [userCodeUpdated, previewOnly, timelineStartTime]);

  useLayoutEffect(() => {
    postMessageToParent({ appReady: true });
  }, []);

  useEventListener('message', (event: PostMessageEventProps) => {
    const {
      data: {
        authorData,
        currentUser,
        iLike,
        loggedOut,
        projectData,
        theme,
        template, // or fork
        sourceInfo,
        isPreviewOnly,
        startTime,
        triggerSave,
        assetPaths
      } = {}
    } = event;

    if (typeof iLike === 'boolean') {
      dispatch(setILikeProject(iLike));
    }

    if (projectData) {
      dispatch(setProjectData(projectData));
    }

    if (currentUser) {
      dispatch(setCurrentUser(currentUser));
    }

    if (loggedOut) {
      dispatch(setCurrentUser(null));
    }

    if (authorData) {
      dispatch(setAuthorData(authorData));
    }

    if (theme) {
      dispatch(setTheme(theme));
    }

    if (template) {
      dispatch(setProjectTemplateData({ projectData: template, authorData }));
    }

    if (sourceInfo) {
      dispatch(setProjectSourceInfo(sourceInfo));
    }

    if (isPreviewOnly) {
      setPreviewOnly(true);
    }

    if (startTime) {
      setTimelineStartTime(startTime);
    }

    if (triggerSave) {
      handleSaveProject();
    }

    if (assetPaths) {
      dispatch(setAssetPaths(assetPaths));
    }
  });

  useEventListener('keydown', (event: KeyboardEvent) => {
    const { metaKey, ctrlKey, shiftKey, altKey, key } = event;

    if (metaKey || ctrlKey || shiftKey || altKey) {
      dispatch(addPressedKey(key));
    }
  });

  useEventListener('keyup', (event: KeyboardEvent) => {
    const { key } = event;

    if (keysPressed.indexOf(key) > -1) {
      dispatch(removePressedKey(key));
    }
  });

  useEventListener('keydown', (event: KeyboardEvent) => {
    const { metaKey, key, ctrlKey, shiftKey } = event;

    if ((metaKey || ctrlKey) && key === 's') {
      // Disable default browser page saving...
      event.preventDefault();
      handleSaveProject();
    }

    if (shiftKey && ctrlKey && key === 'ArrowUp') {
      dispatch(redo());
    }

    if (shiftKey && ctrlKey && key === 'ArrowDown') {
      dispatch(undo());
    }
  });

  useEventListener('touchstart', (e) => {
    e.preventDefault();
  });

  useEffect(() => {
    if (!window?.localStorage) {
      console.warn(
        "localStorage has been disabled, therefor screen dimensions won't be saved"
      );
      return;
    }

    Object.keys(screens).forEach((key) => {
      document.documentElement.style.setProperty(
        `--${key}`,
        `${screens[key]}px`
      );
    });

    window.localStorage.setItem('screens', JSON.stringify(screens));
  }, [screens]);

  const checkAndSetScreenDimensions = useCallback(
    (key: string, value: number) => {
      if (!window?.localStorage) {
        console.warn(
          "localStorage has been disabled, therefor screen dimensions won't be saved"
        );
        return;
      }

      const screens = JSON.parse(
        window.localStorage?.getItem?.('screens') || '{}'
      );

      if (screens[key]) {
        dispatch(setScreenDimension({ key, value: screens[key] }));
      } else {
        dispatch(setScreenDimension({ key, value }));
      }
    },
    [dispatch]
  );

  return (
    <div
      className={cn(classes.app, `theme-${currentTheme}`, {
        previewOnly,
        previewAppReady
      })}
    >
      <ProjectExplorer
        className={classes.ProjectExplorerContainer}
        onMount={checkAndSetScreenDimensions}
      />
      <CodeEditor
        className={classes.CodeEditorContainer}
        onMount={checkAndSetScreenDimensions}
      />
      <VisualPreview
        className={classes.VisualPreviewContainer}
        onMount={checkAndSetScreenDimensions}
      />
      <Timeline
        className={classes.TimelineContainer}
        onMount={checkAndSetScreenDimensions}
      />
      <div id="modal-container"></div>
    </div>
  );
};

export default App;
