import React from 'react';
import cn from 'classnames';
import classes from './Button.module.css';

interface ButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  inverted?: boolean;
  danger?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  inverted,
  danger
}) => {
  return (
    <button
      className={cn(classes.Button, className, {
        [classes.inverted]: inverted,
        [classes.danger]: danger
      })}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};

export default Button;
