import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  screens: {
    [key: string]: number;
  };
  keysPressed: string[]; // 'Meta' | 'Alt' | 'Shift' | 'Control'
}

const initialState: UIState = {
  screens: {},
  keysPressed: []
};

// onMount
// check localStorage
// has values > setScreenDimension > update vars
// no values > getValues > setScreenDimension > update vars + setLocalStorageValues

// onResize
// getValues > setScreenDimension > update vars + setLocalStorageValues

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    addPressedKey: (state, action: PayloadAction<string>) => {
      state.keysPressed.push(action.payload);
    },
    removePressedKey: (state, action: PayloadAction<string>) => {
      state.keysPressed = state.keysPressed.filter(
        (key) => key !== action.payload
      );
    },
    setScreenDimension: (
      state,
      action: PayloadAction<{ key: string; value: number }>
    ) => {
      const { key, value } = action.payload;
      state.screens[key] = value;
    }
  }
});

export const {
  addPressedKey,
  removePressedKey,
  setScreenDimension
} = ui.actions;

export default ui.reducer;
