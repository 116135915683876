import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { RootState } from '../../../appState/rootReducer';

const useErrors = (
  currentFilePathName: string,
  language: string
): monaco.editor.IMarkerData[] => {
  // force build

  const { userCodeError, userImportErrors, userLintErrors } = useSelector(
    (state: RootState) => state.project
  );

  const importErrors = useMemo(() => {
    const errors: monaco.editor.IMarkerData[] = [];

    userImportErrors.forEach(({ filePathName, ...rest }) => {
      if (filePathName === currentFilePathName) {
        // @ts-ignore
        errors.push(rest);
      }
    });

    return errors;
  }, [currentFilePathName, userImportErrors]);

  // These are the runtime errors picked up by the try / catch
  const codeErrors = useMemo(() => {
    const errors: monaco.editor.IMarkerData[] = [];

    const { message, filePathName, name, line, column } = userCodeError;

    if (monaco && message && currentFilePathName === filePathName) {
      errors.push({
        startLineNumber: line,
        endLineNumber: line,
        startColumn: column,
        endColumn: column + (name?.length || 1),
        message,
        severity: monaco.MarkerSeverity.Error
      });
    }

    return errors;
  }, [userCodeError, currentFilePathName]);

  // These are picked up by Eslint
  const lintErrors = useMemo(() => {
    const errors: monaco.editor.IMarkerData[] = [];

    if (monaco && userLintErrors.length) {
      userLintErrors.forEach(
        ({ line, column, message, severity = 8, filePathName }) => {
          if (message && language !== 'html') {
            errors.push({
              startLineNumber: line,
              endLineNumber: line,
              startColumn: column,
              endColumn: column + (filePathName?.length || 0),
              message,
              severity
            });
          }
        }
      );
    }

    return errors;
  }, [userLintErrors, language]);

  const filteredErrors = useMemo(() => {
    // We don't want to show duplicate errors, so remove any code errors if they
    // already exists in the lint errors...

    const errors = [...lintErrors];

    codeErrors.forEach((e) => {
      const error = errors.find(
        ({ startLineNumber }) => startLineNumber === e.startLineNumber
      );
      if (!error) {
        errors.push(e);
      }
    });

    return errors;
  }, [codeErrors, lintErrors]);

  return [...filteredErrors, ...importErrors];
};

export default useErrors;
