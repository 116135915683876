import { original, produce, produceWithPatches } from 'immer';
import { CodeObjectProps, ProjectState } from '@looop/common-types';

export const deleteChildIds = (state: ProjectState, id: string): void => {
  const { files } = state.projectData;
  const filesObj = original(files);
  const filesArray = filesObj ? Object.values(filesObj) : [];
  const parentFolder = filesArray.find(
    ({ childIds }) => childIds && childIds.indexOf(id) > -1
  );

  if (parentFolder?.id) {
    const childIndex =
      state.projectData.files?.[parentFolder.id]?.childIds?.indexOf(id);

    if (childIndex && childIndex > -1) {
      state.projectData.files?.[parentFolder.id]?.childIds?.splice(
        childIndex,
        1
      );
    }
  }
};

export const getNestedPath = (
  files: CodeObjectProps,
  currentId: string
): string => {
  const folders = Object.values(files).filter(({ type }) => type === 'folder');

  let keepFinding = true;
  let path = '/';
  let id = currentId;

  while (keepFinding) {
    // eslint-disable-next-line no-loop-func
    const parent = folders.find(({ childIds }) => {
      if (childIds) {
        return childIds.indexOf(id) > -1;
      }
      return false;
    });

    if (parent) {
      path = `/${parent.name}${path}`;
      id = parent.id;
    } else {
      keepFinding = false;
    }
  }

  return path;
};

interface ProduceWithPatchParams {
  state: ProjectState;
  undoable: boolean;
  actionType: string;
  mutations: (state: ProjectState) => void;
}

const destructiveActions = [
  'codeUpdate',
  'addNewFile',
  'deleteFileOrFolder',
  'updateFileName',
  'moveFile'
];

export const produceWithPatch = ({
  state,
  undoable,
  actionType,
  mutations
}: ProduceWithPatchParams): ProjectState | undefined => {
  if ((!undoable || state.isDetached) && !state.overwriteHistory) {
    mutations(state);
    return;
  }

  const pointer = state.projectData.undoStackPointer;
  const total = state.projectData.undoStack?.length || 0;

  const atLatestAction = pointer === total - 1;
  const isDestructive = destructiveActions.indexOf(actionType) > -1;

  if (!state.overwriteHistory) {
    if (!atLatestAction && total && isDestructive) {
      // Warn user what's going to happen
      state.isDetached = true;
      return;
    } else {
      state.isDetached = false;
    }
  }

  const [nextState, patches, inversePatches] = produceWithPatches(
    original(state),
    (draft: ProjectState) => {
      mutations(draft);
    }
  );

  // Don't update history if just clicking around
  if (!atLatestAction && !isDestructive && total) return nextState;

  return produce(nextState, (draft) => {
    if (!draft?.projectData) return;

    if (!draft.projectData?.undoStack) {
      draft.projectData.undoStack = [];
    }

    draft.projectData.undoStackPointer++;

    if (draft.overwriteHistory) {
      draft.projectData.undoStack.length = draft.projectData.undoStackPointer;
      draft.overwriteHistory = false;
      draft.hasShownDetachedWarning = false;
      draft.isDetached = false;
    }

    draft.projectData.undoStack[draft.projectData.undoStackPointer] = {
      actionType,
      timestamp: Date.now(),
      patches,
      inversePatches
    };

    // Todo: add compression instead!

    // const cap = draft.projectData.undoStackFilterCap;
    // const filterLimitNumber = 250 + cap;
    // const keepEveryNth = 5;

    // Every time we hit a multiple of 250, remove 4 out of 5 of the last 250
    // Todo: check this works properly...

    // if (draft.projectData.undoStackPointer % filterLimitNumber === 0) {
    //   draft.projectData.undoStack = draft.projectData.undoStack.filter(
    //     ({ actionType }, i) => {
    //       if (actionType !== 'codeUpdate') return true;

    //       if (i <= cap) return true; // Only effect items that we've not already filtered

    //       if (i % keepEveryNth === 0) return true;

    //       return false;
    //     }
    //   );

    //   draft.projectData.undoStackPointer =
    //     draft.projectData.undoStack.length - 1;
    //   draft.projectData.undoStackFilterCap = draft.projectData.undoStackPointer;
    // }
  });
};

export const cleanupSelectedFile = (
  draft: ProjectState,
  fileId: string
): void => {
  if (!draft.projectData) return;

  if (fileId === draft.projectData.currentFileId) {
    draft.projectData.currentFileId = '';
  }

  if (fileId === draft.projectData.previewingId) {
    draft.projectData.previewingId = '';
  }

  const index = draft.projectData.viewing?.indexOf(fileId);

  if (typeof index === 'number' && index > -1) {
    draft.projectData.viewing?.splice(index, 1);
  }
};
