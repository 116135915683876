import { useRef, useEffect, MutableRefObject } from 'react';

type CallbackProp = (e: MouseEvent) => void;

const useOuterClick = (
  callback: CallbackProp
): MutableRefObject<HTMLElement> => {
  const innerRef = useRef<HTMLElement>(null);
  const callbackRef = useRef<CallbackProp | null>(null);

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);

    // read most recent callback and innerRef dom node from refs
    function handleClick(e: MouseEvent) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !(e.target instanceof Node && innerRef.current.contains(e.target))
      ) {
        callbackRef.current(e);
      }
    }
  }, []);

  // @ts-ignore
  return innerRef;
};

export default useOuterClick;
