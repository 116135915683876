import React, { useMemo, useCallback, useEffect } from 'react';
import classes from './TimelineFooter.module.css';

interface ITimelineZoomWidthProps {
  timelineWidth: number;
  setZoomWidth: (w: number) => void;
  zoomWidth: number;
  lastResize: number; // timestamp to trigger a re-render
}

const TimelineFooter: React.FC<ITimelineZoomWidthProps> = ({
  timelineWidth,
  zoomWidth,
  setZoomWidth,
  lastResize
}) => {
  const maxZoomWidth = timelineWidth / 2;
  const minZoomWidth = 28;

  const handleZoom = useCallback(
    (value: number) => {
      let v = value;

      v = v < minZoomWidth ? minZoomWidth : v;
      v = v > maxZoomWidth ? maxZoomWidth : v;

      setZoomWidth(v);
    },
    [maxZoomWidth, setZoomWidth]
  );

  const ZoomBar = useMemo(() => {
    const handleResize = (e: MouseEvent) => {
      const offsetLeft = window.innerWidth - timelineWidth;
      handleZoom(e.pageX - offsetLeft);
    };

    return (
      <div
        className={classes.zoomBarContainer}
        style={{ width: timelineWidth }}
      >
        <div
          className={classes.zoomBar}
          style={{
            width: zoomWidth
          }}
        >
          <div className={classes.zoomHandleLeft}></div>
          <div
            className={classes.zoomHandleRight}
            onMouseDown={(e) => {
              e.preventDefault();
              document.addEventListener('mousemove', handleResize, false);
              document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', handleResize);
              });
            }}
          ></div>
        </div>
      </div>
    );
  }, [zoomWidth, handleZoom, timelineWidth]);

  useEffect(() => {
    // reset when resizing or page load
    handleZoom(maxZoomWidth);
  }, [lastResize, handleZoom, maxZoomWidth]);

  return <div className={classes.TimelineFooter}>{ZoomBar}</div>;
};

export default TimelineFooter;
